import React from 'react'
import styles from './WatchNext.module.scss'
import SideBarBtn from '../SideBarBtn/SideBarBtn';
import { useWatchNextPage, useWatchNextRandomVideo } from 'modules/website/hooks/useWatchNextPage';
import ContentCardLoading from '../../ContentCard/ContentCardLoading/ContentCardLoading';
import ContentCard from '../../ContentCard/ContentCard';
import ApiURLs from 'services/BackendApi/BackendApiURLs';
import { useNavigate } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
const handleOnclickRandom = () => {

}

export default function WatchNext(props) {
    const navigate = useNavigate();
    const { btnMore, quantity, alias } = props;
    const { isLoading, isError, payload, error } = useWatchNextPage({
        quantity: quantity,
        sortby: "random_" + (Math.floor(Date.now() / 1000)).toString(),
        alias: alias,
        /* cat1:'117120',
        cat2:'116609',
        cat3:'117121',
        cat4:'116427',
        cat5:'115618' */
    });

    //TODO: Buscar alternativa a no hacer dos peticiones al backend
    const { isLoading: isLoading2, isError: isError2, payload: payload2, error: error2 } = useWatchNextRandomVideo();
    //console.log(payload2)

    return (
        <div>
            <div className={`${styles.title}`}>
                Watch Next
            </div>
            <div>
                {isLoading &&
                    <div>
                        <ContentCardLoading />
                        <ContentCardLoading />
                        <ContentCardLoading />
                        <ContentCardLoading />
                        <ContentCardLoading />
                        <ContentCardLoading />
                    </div>
                }
                {isError && <div>{error}</div>}
                {payload && (
                    payload.records?.record?.map((item, index) => (
                        <ContentCard key={index} title={item.title} image={ApiURLs.baseURL + item.thumbnail} description={item.blurb} nav={item.id} />
                    )
                    ))
                }
            </div>
            {
                isLoading2 && btnMore &&
                <div className='d-flex justify-content-center'>
                    <Placeholder.Button variant="primary" xs={6} className={`${styles.btnLoading}`}/>
                </div>
            }
            {
                btnMore && payload2 && (
                    <div className='d-flex justify-content-center'>
                        <SideBarBtn text="Random Video" func={() => navigate(payload2[0].alias)} />
                    </div>
                )
            }
        </div>
    )
}
