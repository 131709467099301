import { useEffect, useState } from 'react';
import apiService from 'services/BackendApi/BackendApi';

export function useLastWebExhibitsPage(props) {

    const [pageContent, setPageContent] = useState({
        isLoading: false,
        isError: false,
        payload: null,
        error: null,
    });

    useEffect(() => {
        setPageContent((prevPageContent) => ({
            ...prevPageContent,
            isLoading: true,
        }));
        apiService
            .GetLastWebExhibits({
            })
            .then((response) => {
                setPageContent((prevPageContent) => ({
                    ...prevPageContent,
                    isError: false,
                    payload: {
                        ...response,
                    },
                }));
            })
            .catch((error) => {
                setPageContent((prevPageContent) => ({
                    ...prevPageContent,
                    isError: true,
                    error: error?.message,
                }));
            })
            .finally(() => {
                setPageContent((prevPageContent) => ({
                    ...prevPageContent,
                    isLoading: false,
                }));
            });
    }, []);

    return pageContent;


}