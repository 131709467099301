import { useEffect, useState } from 'react';
import apiService from 'services/BackendApi/BackendApi';

export function useNewsnEventsPage(props) {
	//const { page } = props;
	//const { alias } = props;
	const [pageContent, setPageContent] = useState({
		isLoading: false,
		isError: false,
		payload: null,
		error: null,
	});

	useEffect(() => {
		setPageContent((prevPageContent) => ({
			...prevPageContent,
			isLoading: true,
		}));
		apiService
			.GetPagination({
				params: {
					respp: 20,
					contenttype: 'news',
					orderby: 'desc',
					//sortby: 'idodate',
				}
			})
			/* .GetNews({
			}) */
			.then((response) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: false,
					payload: {
						...response,
					},
				}));
			})
			.catch((error) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: true,
					error: error?.message,
				}));
			})
			.finally(() => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isLoading: false,
				}));
			});
	}, []);

	return pageContent;
}
