import React from 'react'
import styles from './Partnership.module.scss'
import ApiURLs from 'services/BackendApi/BackendApiURLs';

export default function Partnership(props) {
    const { sponsors } = props;
    return (
        <div className={`${styles.partnership}`}>
            {
                sponsors.length > 0 && (
                    <div className={`${styles.title}`}>
                        In partnership with:
                    </div>
                )
            }
            <div className={`${styles.sponsors}`}>
                {
                    sponsors.map((item, index) => (
                        <div className={`${styles.sponsor}`} key={index}>
                            <a href={item.link} target="_blank" rel="noreferrer">
                                <img src={ApiURLs.baseURL + '/glifos/glifos/file/get/' + item.id_logo} />
                            </a>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
