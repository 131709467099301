import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import styles from './CommentForm.module.scss'
import ApiURLs from 'services/BackendApi/BackendApiURLs';
import { useParams } from 'react-router-dom';
import Alert from 'modules/website/components/Alert/Alert';

export default function CommentForm(props) {
  // Estados para almacenar los valores de los campos
  const { nid } = props;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [hidden, setHidden] = useState(nid);
  const { alias } = useParams();

  //Estado del submit
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');


  // Manejadores de cambio para actualizar los estados cuando se ingresan datos
  const handleNameChange = (e) => setName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleCommentChange = (e) => setComment(e.target.value);
  const handleHiddenChange = (e) => setHidden(e.target.value);

  // Manejador de envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setLoading(true);
    const formData = {
      nid: nid,
      name: name,
      email: email,
      comment: comment,
      alias: alias,
      hidden: hidden,
      // Otros datos que quieras enviar al backend
    };
  
    try {
      const response = await fetch(ApiURLs.baseURL + '/glifos/glifoscomment/post-handler', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Otros encabezados si es necesario
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        const jsonResponse = await response.json(); // Obtener el JSON de la respuesta
        //console.log(jsonResponse);
  
        // Aquí puedes realizar acciones adicionales según la respuesta del servidor
        if (jsonResponse.status === 200) {
          //console.log('Comentario enviado con éxito');
          setStatus(jsonResponse);
          props.updateComments();
        } else {
          //console.error('Error al enviar el comentario:', jsonResponse.message);
          setStatus(jsonResponse);
        }
      } else {
        //console.error('Error al enviar el comentario. Código de estado:', response.status);
        setStatus({status: 500, message:'An error has occurred, please try again later'});
      }
    } catch (error) {
      //console.error('Error de red:', error);
      setStatus({status: 500, message: 'An error has occurred, please try again later'});
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit} className={loading ? `${styles.loadForm}`:''}>
      <Form.Group controlId="" className={`${styles.group}`}>
        <Form.Label className={`${styles.title}`}>Name</Form.Label>
        <Form.Control type="text" className={`${styles.text}`} value={name} onChange={handleNameChange} />
      </Form.Group>
      <Form.Group controlId="exampleForm.ControlInput1" className={`${styles.group}`}>
        <Form.Label className={`${styles.title}`}>E-mail</Form.Label>
        <Form.Control type="email" className={`${styles.text}`} value={email} onChange={handleEmailChange} />
      </Form.Group>
      <Form.Group controlId="exampleForm.ControlTextarea1" className={`${styles.group}`}>
        <Form.Label className={`${styles.title}`}>Comment</Form.Label>
        <Form.Control as="textarea" className={`${styles.text}`} value={comment} onChange={handleCommentChange} />
      </Form.Group>
      <Form.Control type="hidden" className={`${styles.text}`} value={hidden} onChange={handleHiddenChange} />
      <Button variant="primary" type="submit" className={`${styles.btn}`} >
        {loading ? 'Sending...' : 'Send'}
      </Button>
      {status.status === 200 && <Alert variant="success" >{status.message}</Alert>}
      {status.status === 500 && <Alert variant="danger">{status.message}</Alert>}
    </Form>
  )
}
