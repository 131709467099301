import React from 'react';
import { Alert as BootstrapAlert } from 'react-bootstrap';

export default function Alert(props) {
	const { variant, children, title, footer } = props;
	return (
		<BootstrapAlert variant={variant} className='w-100'>
			{title && <BootstrapAlert.Heading>{title}</BootstrapAlert.Heading>}
			{children}
			{footer && (
				<>
					<hr />
					{footer}
				</>
			)}
		</BootstrapAlert>
	);
}
