import React, { useState } from 'react'
import styles from './MetadataOptions.module.scss'
import { Accordion, Button, Col, Container, Row } from 'react-bootstrap';
import Itext from '../../AccordionItext/Itext/Itext';
import CommentForm from './CommentForm/CommentForm';
import Comment from './Comment/Comment';
import CommentsOption from './CommentsOption/CommentsOption';
export default function MetadataOptions(props) {
    const { description, keywords, others, comments, nid } = props;
    const [showDesc, setShowDesc] = useState(true);
    const [showKey, setShowKey] = useState(false);
    const [showComment, setShowComment] = useState(false);
    const [showOthers, setShowOthers] = useState(false);

    const handleDescClick = () => {
        setShowDesc(true);
        setShowKey(false);
        setShowComment(false);
        setShowOthers(false);
    }

    const handleKeyClick = () => {
        setShowDesc(false);
        setShowKey(true);
        setShowComment(false);
        setShowOthers(false);
    }

    const handleCommentClick = () => {
        setShowDesc(false);
        setShowKey(false);
        setShowComment(true);
        setShowOthers(false);
    }

    const handleOthersClick = () => {
        setShowDesc(false);
        setShowKey(false);
        setShowComment(false);
        setShowOthers(true);
    }

    return (
        <div className={`${styles.options}`}>
            <div className={`${styles.buttons}`}>

                <Button className={`${styles.button} ` + (showDesc ? (`${styles.btnActive}`) : (''))} onClick={handleDescClick}>
                    About the Video
                </Button>
                {
                    others?.result?.length > 0 ? (
                        <Button className={`${styles.button} ` + (showOthers ? (`${styles.btnActive}`) : (''))} onClick={handleOthersClick}>
                            More Information
                        </Button>
                    ) : (
                        others?.more_about ? (
                            <Button className={`${styles.button} ` + (showOthers ? (`${styles.btnActive}`) : (''))} onClick={handleOthersClick}>
                                More Information
                            </Button>
                        ) : (
                            <></>
                        )
                    )
                }
                <Button className={`${styles.button} ` + (showKey ? (`${styles.btnActive}`) : (''))} onClick={handleKeyClick}>
                    Keywords
                </Button>
                <Button className={`${styles.button} ` + (showComment ? (`${styles.btnActive}`) : (''))} onClick={handleCommentClick}>
                    Leave your Comment
                </Button>
            </div>
            <div className={`${styles.information}`}>
                <div className={`${styles.description} ` + (showDesc ? (`${styles.active}`) : (`${styles.desactive}`))}>
                    
                <div
                        dangerouslySetInnerHTML={{ __html:description }}
                    />
                    
                </div >
                <div className={`${styles.keywords} ` + (showKey ? (`${styles.active}`) : (`${styles.desactive}`))}>
                    <ul>
                        {
                            keywords?.map((k, index) => {
                                return (
                                    <li key={index} >
                                        <a href={`/search/?keywords=${k}`}>{k}</a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    {/* <Container>
                        <Row>
                            {
                                keywords.map((k, index) => {
                                    return (
                                        <Col xs={4} sm={3} md={2} key={index}>
                                            {k}
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Container> */}
                </div>
                <div className={`${styles.comment} ` + (showOthers ? (`${styles.active}`) : (`${styles.desactive}`))}>

                    <Accordion className={`${styles.accordion}`}>
                        {
                            others?.more_about && (() => {
                                others.more_about.type = 'biography';
                                return <Itext item={others.more_about} index={0} moreInfo />;
                            })()
                        }
                        {
                            others?.result?.map((item, index) => {
                                item.type = 'biography';
                                return (
                                    <Itext item={item} index={index+1} key={index} moreInfo />
                                )
                                /* if(item.type === 'biography'){
                                    item.ids.map((i, index) => {
                                        
                                    }) */
                                /* }else{
                                    return (
                                        <Itext item={item} index={index} key={index} moreInfo/>
                                    )
                                } */
                            })
                        }
                    </Accordion>
                </div>
                <div className={`${styles.comment} ` + (showComment ? (`${styles.active}`) : (`${styles.desactive}`))}>
                    {/* <div className={`${styles.title}`}> */}
                        {/* {
                            comments?.length > 0 && (
                                <div className={`${styles.comments}`}>
                                    {
                                        comments.map((c, index) => {
                                            return (
                                                <Comment name={c.user_name} date={c.date_text} body={c.body} key={index} />
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                        <CommentForm nid={nid} /> */}
                        <CommentsOption nid={nid} />
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}
