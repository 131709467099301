import React from 'react'
import styles from './GlifosMetadataLoading.module.scss'
import { Col, Container, Placeholder, Row } from 'react-bootstrap'
export default function GlifosMetadataLoading() {
    return (
        <Container className='py-5'>
            <Row>
                <Col md={12}>
                    {/* {!isLoading && !isError && payload && (
                        <GlifosRichMediaPlayer src={payload?.metadata?.video?.src} />
                    )}
                    <CreateSegment /> */}
                    <Placeholder animation="wave" className={`${styles.videoplaceholder}`} >
                        <Placeholder xs={12} size="lg" />
                    </Placeholder>

                    <Placeholder animation="wave" className={`${styles.segment}`}>
                        <Placeholder xs={4} size="lg" className={`${styles.title}`}/>
                        <Placeholder xs={1} size="lg" className={`${styles.square}`}/>
                        <Placeholder xs={1} size="lg" className={`${styles.square}`} /> 
                        <Placeholder xs={1} size="lg" className={`${styles.square}`} /> 
                        <Placeholder xs={1} size="lg" className={`${styles.square}`} />
                    </Placeholder>
                </Col>
                <Col md={12}>
                    <div className={`${styles.main}`}>
                        <div className={`${styles.title}`}>
                            <Placeholder animation="wave" className={`${styles.placetitle}`} >
                                <Placeholder xs={6} size="lg" />
                            </Placeholder>
                        </div>
                        <div className={`${styles.donor}`}>
                            <Placeholder animation="wave" >
                                <Placeholder className={`${styles.placetitle}`} xs={2} size="lg" />
                            </Placeholder>
                        </div>
                        <div className={`${styles.statistics}`}>
                            <Placeholder animation="wave" >
                                <Placeholder className={`${styles.placetitle}`} xs={1} size="lg" /> {"|"} <Placeholder className={`${styles.placetitle}`} xs={1} size="lg" /> {"|"} <Placeholder className={`${styles.placetitle}`} xs={1} size="lg" />
                            </Placeholder>
                        </div>
                        <div className={`${styles.itexts}`}>
                            {/* <AccordionItext itext={itext} /> */}
                            <Placeholder animation="wave" className={`${styles.itext}`}>
                                <Placeholder className={`${styles.placetitle}`} xs={12} size="lg" />
                            </Placeholder>
                            <Placeholder animation="wave" className={`${styles.itext}`}>
                                <Placeholder className={`${styles.placetitle}`} xs={12} size="lg" />
                            </Placeholder>
                        </div>
                        <div>
                            <div className={`${styles.categories}`}>
                                <Placeholder animation="wave" >
                                    <Placeholder className={`${styles.placetitle}`} xs={2} size="lg" />
                                </Placeholder>
                                {' : '}
                                <Placeholder animation="wave" >
                                    <Placeholder className={`${styles.placetitle}`} xs={1} size="lg" /> <Placeholder className={`${styles.placetitle}`} xs={1} size="lg" /> <Placeholder className={`${styles.placetitle}`} xs={1} size="lg" />
                                </Placeholder>
                            </div>
                            <div className={`${styles.categories}`}>
                                <Placeholder animation="wave" >
                                    <Placeholder className={`${styles.placetitle}`} xs={2} size="lg" />
                                </Placeholder>
                                {' : '}
                                <Placeholder animation="wave" >
                                    <Placeholder className={`${styles.placetitle}`} xs={1} size="lg" /> <Placeholder className={`${styles.placetitle}`} xs={1} size="lg" /> <Placeholder className={`${styles.placetitle}`} xs={1} size="lg" />
                                </Placeholder>
                            </div>
                        </div>
                        <div>
                            {/* <MetadataOptions description={metadata.description} keywords={metadata.keywords} /> */}
                            <div className={`${styles.options}`}>
                                <div className={`${styles.buttons}`}>
                                    <Placeholder xs={2} size="lg" className={`${styles.button} ${styles.btnActive}`} />
                                    <Placeholder xs={2} size="lg" className={`${styles.button}`} />
                                    <Placeholder xs={2} size="lg" className={`${styles.button}`} />
                                </div>
                                <div className={`${styles.information}`}>
                                    <div className={`${styles.description}`}>
                                        <Placeholder as='p' animation="wave" >
                                            <Placeholder xs={8} />  <Placeholder xs={3} />
                                            <Placeholder xs={4} /> <Placeholder xs={4} /> <Placeholder xs={3} />
                                            <Placeholder xs={6} /> <Placeholder xs={2} /> <Placeholder xs={3} />
                                            <Placeholder xs={3} /> <Placeholder xs={2} /> <Placeholder xs={3} /> <Placeholder xs={3} />
                                            <Placeholder xs={2} /> <Placeholder xs={2} /> <Placeholder xs={3} />
                                        </Placeholder>
                                    </div >
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>

    )
}
