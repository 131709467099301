import React, { useEffect, useState } from 'react';
import styles from './TaxonomyView.module.scss';
import { Link, useParams } from 'react-router-dom';
import {
	useTaxonomyPagination,
	useTaxonomyTitle,
} from 'modules/website/hooks/useTaxonomyPage';
import ContentCard from 'modules/website/components/ContentCard/ContentCard';
import ApiURLs from 'services/BackendApi/BackendApiURLs';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'modules/website/components/Pagination/Pagination';
import TaxonomyLoading from 'modules/website/components/TaxonomyLoading/TaxonomyLoading';

export default function TaxonomyView() {
	//const tid = location.pathname.replace('/taxonomy/term/', '');
	const { tid } = useParams();
	const [page, setPage] = useState(1);
	const [type, setType] = useState('');

	//TODO: buscar cuando sea de tipo location de un video

	const { isLoading, isError, payload, error } = useTaxonomyPagination({
		category: type !== 'location_tami' ? 'category-' + tid : '',
		location: type === 'location_tami' ? 'location-' + tid : '',
		page: page,
		respp: 8,
		tid: tid,
	});

	/* useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []); */

	/* const { isLoading, isError, payload, error } = useCategoryTreePage({
        category: type !== 'location_tami' ? 'category-' + tid : '',
        location: type === 'location_tami' ? 'location-' + tid : '',
        page: page,
        respp: 8,
        tid: tid,
    }); */

	//TODO: buscar la manera que no se hagan dos peticiones
	const { payload: payloadTitle } = useTaxonomyTitle({
		tid: tid,
	});

	useEffect(() => {
		if (payloadTitle) {
			setType(payloadTitle[0].vid);
		}
	}, [payloadTitle]);

	const url = new URL(window.location.href);
	const pageUrl = url.searchParams.get('page');

	useEffect(() => {
		setPage(pageUrl);
	}, [pageUrl]);

	if (isLoading) {
		return <TaxonomyLoading />;
	}
	if (isError) {
		return <div>{error}</div>;
	}
	if (payload) {
		return (
			<div>
				<div className={`${styles.title} py-5`}>{payload?.title}</div>
				{payload?.description && (
					<div
						className={`${styles.description}`}
						dangerouslySetInnerHTML={{
							__html: payload.description,
						}}
					/>
				)}
				<div>
					<Row>
						{payload?.solr_content?.records?.record &&
							(Array.isArray(
								payload?.solr_content?.records?.record
							) ? (
								payload?.solr_content?.records?.record?.map(
									(item, index) => {
										return (
											<Col
												key={index}
												md={12}
												lg={6}
												className='mb-4'
											>
												<ContentCard
													key={index}
													title={item?.title}
													image={
														ApiURLs.baseURL +
														item.thumbnail
													}
													description={item?.blurb}
													nav={item?.id}
												/>
											</Col>
										);
									}
								)
							) : (
								<Col md={12} lg={6} className='mb-4'>
									<ContentCard
										title={
											payload?.solr_content?.records
												?.record.title
										}
										image={
											ApiURLs.baseURL +
											payload?.solr_content?.records
												?.record.thumbnail
										}
										description={
											payload?.solr_content?.records
												?.record.blurb
										}
										nav={
											payload?.solr_content?.records
												?.record.id
										}
									/>
								</Col>
							))}
					</Row>
				</div>
				<Pagination
					result_header={payload?.solr_content?.result_header}
				/>
				{payload?.parent_category?.length > 0 && (
					<div className={`${styles.categories}`}>
						<div>Categories:</div>
						{payload?.parent_category?.map((item, index) => {
							return (
								<Link
									key={index}
									className={`${styles.category}`}
									to={item.tid}
								>
									{item.name}
								</Link>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}
