import React from 'react'
import styles from './BrowseBy.module.scss'
import { useBrowseBy } from 'modules/website/hooks/useBrowseByPage';
import ContentCardLoading from '../../ContentCard/ContentCardLoading/ContentCardLoading';
import ContentCard from '../../ContentCard/ContentCard';
import { useNavigate } from 'react-router-dom';
import ApiURLs from 'services/BackendApi/BackendApiURLs';
export default function BrowseBy() {
    const { isLoading, isError, payload, error } = useBrowseBy();
    const navigate = useNavigate();
    return (
        <div>
            <div className={`py-5 ${styles.title}`}>
                Browse by
            </div>
            <div>
                {isLoading &&
                    <div>
                        <ContentCardLoading />
                        <ContentCardLoading />
                        <ContentCardLoading />
                        <ContentCardLoading />
                        <ContentCardLoading />
                        <ContentCardLoading />
                    </div>
                }
                {isError && <div>{error.message}</div>}
                {payload && (
                    Object.values(payload).map((item, index) => (
                        <ContentCard 
                        key={index} 
                        title={item.name} 
                        nav={item.tid}
                        image={ApiURLs.baseURL+'/glifos/sites/default/files'+item.image}
                        />
                    )
                    ))
                }
            </div>
        </div>
    )
}
