import { useEffect, useState } from 'react';
import apiService from 'services/BackendApi/BackendApi';

export function useNodePage(props) {
	const { nid, alias } = props;
	const [pageContent, setPageContent] = useState({
		isLoading: false,
		isError: false,
		payload: null,
		error: null,
	});

	useEffect(() => {
		setPageContent((prevPageContent) => ({
			...prevPageContent,
			isLoading: true,
		}));
		if (nid) {
			apiService
				.GetNodeInfo({
					urlParams: {
						nid: nid,
					},
					params: {
						_format: 'json',
					},
				})
				.then((response) => {
					setPageContent((prevPageContent) => ({
						...prevPageContent,
						isError: false,
						payload: {
							...response.data,
						},
					}));
				})
				.catch((error) => {
					setPageContent((prevPageContent) => ({
						...prevPageContent,
						isError: true,
						error: error?.message,
					}));
				})
				.finally(() => {
					setPageContent((prevPageContent) => ({
						...prevPageContent,
						isLoading: false,
					}));
				});
		} else{
			apiService
				.GetPageInfo({
					urlParams: {
						alias: alias,
					},
					params: {
						_format: 'json',
					},
				})
				.then((response) => {
					setPageContent((prevPageContent) => ({
						...prevPageContent,
						isError: false,
						payload: {
							...response.data,
						},
					}));
				})
				.catch((error) => {
					setPageContent((prevPageContent) => ({
						...prevPageContent,
						isError: true,
						error: error?.message,
					}));
				})
				.finally(() => {
					setPageContent((prevPageContent) => ({
						...prevPageContent,
						isLoading: false,
					}));
				});
		}
	}, [nid]);

	return pageContent;

}