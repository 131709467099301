import React from 'react'
//import styles from './LoadingColumn.module.scss';
import ContentCardLoading from '../ContentCard/ContentCardLoading/ContentCardLoading';
export default function LoadingColumn(props) {
    const { quantity } = props;
    return (
        <div>
            {
                [...Array(quantity)].map((item, index) => {
                    return (
                        <ContentCardLoading key={index} />
                    )
                })
            }
        </div>
    )
}
