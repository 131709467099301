import React from 'react'
import styles from './NotFoundView.module.scss'

export default function NotFoundView() {
  return (
    <div className='py-5'>
        <div className={`${styles.title}`}>ERROR</div>
        <img src="https://media.giphy.com/media/39hp8Szpv5cuxqSPR2/giphy.gif" alt="error" />
    </div>
  )
}
