import React, { useEffect, useState } from 'react';
import styles from './GlifosCategoryTreeView.module.scss';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ContentCard from 'modules/website/components/ContentCard/ContentCard';
import ApiURLs from 'services/BackendApi/BackendApiURLs';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'modules/website/components/Pagination/Pagination';
import TaxonomyLoading from 'modules/website/components/TaxonomyLoading/TaxonomyLoading';
import { useCategoryTreePage } from 'modules/website/hooks/useCategoryTreePage';
import { useSearchParams } from 'react-router-dom';

export default function GlifosCategoryTreeView() {
	const { tid, page_id } = useParams();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const page = searchParams.get('page') || 1;
	const [type, setType] = useState('');

	//TODO: buscar cuando sea de tipo location de un video

	const { isLoading, isError, payload, error } = useCategoryTreePage({
		category: type !== 'location_tami' ? 'category-' + tid : '',
		location: type === 'location_tami' ? 'location-' + tid : '',
		page: page,
		respp: 12,
		tid: tid,
	});

	useEffect(() => {
		if (page_id) {
			navigate(`/glifoscategorytree/category/${tid}?page=${page_id}`);
		}
		// eslint-disable-next-line
	}, [page_id]);

	if (isLoading) {
		return <TaxonomyLoading />;
	}
	if (isError) {
		return <div>{error}</div>;
	}
	if (payload) {
		return (
			<div>
				<div className={`${styles.title} py-5`}>{payload.title}</div>
				{payload?.description && (
					<div
						className={`${styles.description}`}
						dangerouslySetInnerHTML={{
							__html: payload.description,
						}}
					/>
				)}
				{/* <div>
                Our collection provides access to primary and secondary source films relating to Texas, U.S.,
                and World History, as well as free lesson plans aligned with Texas teaching standards (TEKS) for grades K-12.
            </div> */}
				<div>
					<Row>
						{payload?.solr_content?.records?.record &&
							(Array.isArray(
								payload?.solr_content?.records?.record
							) ? (
								payload?.solr_content?.records?.record?.map(
									(item, index) => {
										return (
											<Col
												key={index}
												md={12}
												lg={6}
												className='mb-4'
											>
												<ContentCard
													key={index}
													title={item.title}
													image={
														ApiURLs.baseURL +
														item.thumbnail
													}
													description={item.blurb}
													nav={item.id}
												/>
											</Col>
										);
									}
								)
							) : (
								<Col md={12} lg={6} className='mb-4'>
									<ContentCard
										title={
											payload?.solr_content?.records
												?.record.title
										}
										image={
											ApiURLs.baseURL +
											payload?.solr_content?.records
												?.record.thumbnail
										}
										description={
											payload?.solr_content?.records
												?.record.blurb
										}
										nav={
											payload?.solr_content?.records
												?.record.id
										}
									/>
								</Col>
							))}
					</Row>
				</div>
				<Pagination
					result_header={payload?.solr_content?.result_header}
				/>
				{payload?.parent_category?.length > 0 && (
					<div className={`${styles.categories}`}>
						<div>Categories:</div>
						{payload?.parent_category?.map((item, index) => {
							return (
								<Link
									key={index}
									className={`${styles.category}`}
									to={item.tid}
								>
									{item.name}
								</Link>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}
