export const ComponentMap = [
        {
                "type": "rich_media_archive",
                "c": "modules/website/views/GlifosRichMediaView/GlifosRichMediaView",
                "l": "right",
        },
        {
                "type": "information",
                "c": "modules/website/views/InformationView/InformationView",
                "l": "right",
        },
        {
                "type": "general_page",
                "c": "modules/website/views/InformationView/InformationView",
                "l": "right",
        },
        {
                "type": "glifos_digital_archive",
                "c": "modules/website/views/GlifosDigitalArchiveView/GlifosDigitalArchiveView",
                "l": "right",
        },
        {
                "type": "main_page",
                "c": "modules/website/views/HomeView/HomeView",
                "l": "right",
        },
        {
                "type": "news",
                "c": "modules/website/views/NewsView/NewsView",
                "l": "right",
        },
        {
                "type":"content_lesson_plan",
                "c":"modules/website/views/EducationLessonView/EducationLessonView",
                "l":"right",
        },
        {
                'type': 'wide_general_page',
                'c': 'modules/website/views/InformationView/InformationView',
                'l': 'central',
        }
];