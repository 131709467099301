import React, { useEffect } from 'react'
import styles from './NewsView.module.scss'
import { useNodePage } from 'modules/website/hooks/useNodePage';
import { useLocation, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import NewsLoading from 'modules/website/components/News/NewsLoading/NewsLoading';
import News from 'modules/website/components/News/News';
import ApiURLs from 'services/BackendApi/BackendApiURLs';
import NotFoundView from '../NotFoundView/NotFoundView';

/* title: response.data.title[0].value,
						body: response.data.body[0].processed,
						image: response.data.field_news_image[0].url */

export default function NewsView() {
  const location = useLocation();
  //location.reload();
  let { alias, nid } = useParams();
    //console.log('alias', alias);
    ///console.log('nid', nid);
    const { isLoading, isError, payload, error } = useNodePage({
        alias: alias,
        nid:nid,
        stylesheet: 'DefaultJSON',
    });

  useEffect(() => {
		const handleAnchorClick = (event) => {
			event.preventDefault();
			const targetId = event.currentTarget.getAttribute('href').substring(1);
			const targetElement = document.getElementById(targetId);
      
			if (targetElement) {
				targetElement.scrollIntoView({ behavior: 'smooth' });
			}
		};

		const anchorLinks = document.querySelectorAll('a[href^="#"]');
		anchorLinks.forEach((link) => {
			link.addEventListener('click', handleAnchorClick);
		});

		return () => {
			anchorLinks.forEach((link) => {
				link.removeEventListener('click', handleAnchorClick);
			});
		};
	}, []);

  var url;
  if(payload?.field_news_image[0].url != null){
    //console.log(payload?.field_news_image[0].url);
    url = new URL(payload?.field_news_image[0].url)
  }
  return (
    <Container>
      {isLoading && <NewsLoading />}
      {!isLoading && isError && <NotFoundView/>}
      {!isLoading && !isError && payload && (
        <News image={payload?.field_news_image[0].url} title={payload.title[0].value}>
          <div dangerouslySetInnerHTML={{ __html: payload.body[0].processed }} />
        </News>)}
    </Container>

  )
}
