//import LoginForm from 'modules/user/components/LoginForm/LoginForm';
//import ErrorMark from 'common/components/AnimatedIcons/ErrorMark/ErrorMark';
//import LogoutForm from 'modules/user/components/LogoutForm/LogoutForm';
//import ProtectedRoute from 'common/routes/PrivateRoute/PrivateRoute';
//import BasicLayout from 'common/layouts/BasicLayout/BasicLayout';
import CenteredLayout from 'common/layouts/CenteredLayout/CenteredLayout';
import SideBarLayout from 'common/layouts/SideBarLayout/SideBarLayout';
import NewsnEvents from 'modules/website/components/NewsnEvents/NewsnEvents';
import BrowseBy from 'modules/website/components/SideBars/BrowseBy/BrowseBy';
import BrowseByCategory from 'modules/website/components/SideBars/BrowseByCategory/BrowseByCategory';
import BrowseByGrade from 'modules/website/components/SideBars/BrowseByGrade/BrowseByGrade';
import BrowseByTopic from 'modules/website/components/SideBars/BrowseByTopic/BrowseByTopic';
import BrowseByYear from 'modules/website/components/SideBars/BrowseByYear/BrowseByYear';
import OtherReources from 'modules/website/components/SideBars/OtherReources/OtherReources';
import SideBar from 'modules/website/components/SideBars/SideBar/SideBar';
//import SideBarArticle from 'modules/website/components/SideBars/SideBarArticle/SideBarArticle';
import SideBarMain from 'modules/website/components/SideBars/SideBarMain/SideBarMain';
import TaxonomySideBar from 'modules/website/components/SideBars/TaxonomySideBar/TaxonomySideBar';
import WatchNext from 'modules/website/components/SideBars/WatchNext/WatchNext';
import AliasNodeView from 'modules/website/views/AliasNodeView/AliasNodeView';
import EducationView from 'modules/website/views/EducationView/EducationView';
import GlifosCategoryTreeView from 'modules/website/views/GlifosCategoryTreeView/GlifosCategoryTreeView';
import GlifosRichMediaView from 'modules/website/views/GlifosRichMediaView/GlifosRichMediaView';
//import GlifosRichMediaView from 'modules/website/views/GlifosRichMediaView/GlifosRichMediaView';
import HomeView from 'modules/website/views/HomeView/HomeView';
import MainPageView from 'modules/website/views/MainPageView/MainPageView';
//import InformationView from 'modules/website/views/InformationView/InformationView';
import NewsnEventsView from 'modules/website/views/News&EventsView/News&EventsView';
import NewsView from 'modules/website/views/NewsView/NewsView';
import NodeView from 'modules/website/views/NodeView/NodeView';
import NotFoundView from 'modules/website/views/NotFoundView/NotFoundView';
import TaxonomyView from 'modules/website/views/TaxonomyView/TaxonomyView';
import TestView from 'modules/website/views/TestView/TestView';
import VideosView from 'modules/website/views/VideosView/VideosView';
import WebExhibitView from 'modules/website/views/WebExhibitView/WebExhibitView';
//import apiService from 'services/BackendApi/BackendApi';
/* 
	NOTA:
	En Element debe ir el Layout y el Layout debe tener como props.children la el view que
	se va usar? 
*/

const DefaultRoutes = [
	{
		path: '/',
		element: <MainPageView />,
	},
	{
		path: '/videos',
		element: (
			<SideBarLayout
				leftBar={
					<SideBar>
						<BrowseBy />
					</SideBar>
				}
			>
				<VideosView />
			</SideBarLayout>
		),
	},
	{
		path: '/education',
		element: (
			<SideBarLayout
				rightBar={
					<SideBar>
						<BrowseByTopic />
						<BrowseByGrade />
						<OtherReources />
					</SideBar>
				}
			>
				<EducationView />
			</SideBarLayout>
		),
	},

	/* {
		path: '/2019_01248',
		element: (
			<SideBarLayout rightBar={
				<SideBar>
					<WatchNext />
				</SideBar>
			}>
				<GlifosRichMediaView>

				</GlifosRichMediaView>
			</SideBarLayout>
		),
	}, */
	{
		path: '/:alias',
		element: <AliasNodeView />,
	},
	{
		path: '/overview/:alias',
		element: (
			<main
				id='main'
				className='d-flex flex-column flex-grow-1 align-items-center'
			>
				<GlifosRichMediaView />
			</main>
		),
	},
	{
		path: '/test-ios',
		element: (
			<CenteredLayout>
				<TestView />
			</CenteredLayout>
		),
	},
	{
		path: '/news-events',
		element: (
			<SideBarLayout
				rightBar={
					<SideBar>
						<BrowseByYear />
						<BrowseByCategory />
					</SideBar>
				}
			>
				<NewsnEventsView />
			</SideBarLayout>
		),
	},
	{
		path: '/web-exhibits',
		element: (
			<SideBarLayout
				rightBar={
					<SideBar>
						<WatchNext btnMore quantity={6} />
					</SideBar>
				}
			>
				<WebExhibitView />
			</SideBarLayout>
		),
	},
	{
		path: '/node/:nid',
		element: (
			/* <SideBarLayout rightBar={
				<SideBar>
					<NewsnEvents quantity={3} title='More News & Events' />
					<BrowseByYear />
					<BrowseByCategory />
				</SideBar>
			}>
				<NewsView />
			</SideBarLayout> */
			<NodeView />
		),
	},
	{
		path: '/taxonomy/term/:tid',
		element: (
			<SideBarLayout rightBar={<TaxonomySideBar />}>
				<TaxonomyView />
			</SideBarLayout>
		),
	},
	{
		path: '/glifoscategorytree/category/:tid/:page_id?',
		element: (
			<SideBarLayout
				rightBar={
					<SideBar>
						<BrowseBy />
					</SideBar>
				}
			>
				<GlifosCategoryTreeView />
			</SideBarLayout>
		),
	},
	/* //NOTA: Esta es la otra opcion para que se pueda diferenciar entre los id de los videos y los alias de las paginas
	{
		path: '/info/:alias',
		element: (
			<SideBarLayout rightBar={SideBarArticle}>
				<InformationView />
			</SideBarLayout>
		),

	}, */

	/* {
		path: '/about',
		element: (
			<SideBarLayout rightBar={SideBarArticle}>
				<AboutView />
			</SideBarLayout>
		),
	},
	{
		path: '/services',
		element: (
			<SideBarLayout rightBar={SideBarArticle}>
				<ServicesView />
			</SideBarLayout>
		),
	},
	{
		path: '/faq',
		element: (
			<SideBarLayout rightBar={SideBarArticle}>
				<InformationView/>
			</SideBarLayout>
		),
	},
	{
		path: '/round-up',
		element: (
			<SideBarLayout rightBar={SideBarArticle}>
				<FilmRoundUpView />
			</SideBarLayout>
		),
	},
	{
		path: '/support-us',
		element: (
			<SideBarLayout rightBar={SideBarArticle}>
				<SupportUsView />
			</SideBarLayout>
		),
	},
	{
		path: '/education',
		element: (
			<SideBarLayout rightBar={SideBarArticle}>
				<EducationView />
			</SideBarLayout>
		),
	},
	{
		path: '/----rich-media-test',
		element: (
			<SideBarLayout rightBar={SideBarArticle}>
				<GlifosRichMediaView>
					
				</GlifosRichMediaView>
			</SideBarLayout>
		),
	},
	
	{
		path: '/----rich-media-test',
		element: (
			<SideBarLayout rightBar={SideBarArticle}>
				<GlifosRichMediaView>
					
				</GlifosRichMediaView>
			</SideBarLayout>
		),
	},
	
	/* {
		path: '/user/login',
		element: (
			<CentralElementLayout classes={['user-login-page']}>
				<LoginForm />
			</CentralElementLayout>
		),
	},
	{
		path: '/user/logout',
		element: (
			<CentralElementLayout
				classes={['user-logout-page']}
				redirectAfterTo='/user/login'
				timeOutToRedirect={3000}>
				<LogoutForm />
			</CentralElementLayout>
		),
	},
	{
		path: '/user/restricted',
		element: (
			<CentralElementLayout
				classes={['user-restricted-page']}
				redirectAfterTo='/'
				timeOutToRedirect={3000}>
				<ErrorMark />
				<div style={{ textAlign: 'center' }}>Error, al acceder.</div>
			</CentralElementLayout>
		),
	}, */

	{
		path: '/404',
		element: (
			<SideBarLayout
				rightBar={
					<SideBar>
						<WatchNext btnMore quantity={3} />
						<NewsnEvents quantity={3} btnMore />
					</SideBar>
				}
			>
				<NotFoundView />
			</SideBarLayout>
		),
	},
	{
		path: '*',
		element: (
			<SideBarLayout
				rightBar={
					<SideBar>
						<WatchNext btnMore quantity={3} />
						<NewsnEvents quantity={3} btnMore />
					</SideBar>
				}
			>
				<NotFoundView />
			</SideBarLayout>
		),
	},
];

export default DefaultRoutes;
