import React from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { createCustomEqual } from 'fast-equals';
import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import mark from 'assets/img/tpmblank.png';
// [END maps_react_map_marker_component]
const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
	if (
		isLatLngLiteral(a) ||
		a instanceof window.google.maps.LatLng ||
		isLatLngLiteral(b) ||
		b instanceof window.google.maps.LatLng
	) {
		return new window.google.maps.LatLng(a).equals(
			new window.google.maps.LatLng(b)
		);
	}
	// TODO extend to other types
	// use fast-equals for other objects
	return deepEqual(a, b);
});

function useDeepCompareMemoize(value) {
	const ref = React.useRef();

	if (!deepCompareEqualsForMaps(value, ref.current)) {
		ref.current = value;
	}
	return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
	React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

const Map = ({ onClick, onIdle, children, style, ...options }) => {
	// [START maps_react_map_component_add_map_hooks]
	const ref = React.useRef(null);
	const [map, setMap] = React.useState();

	React.useEffect(() => {
		if (ref.current && !map) {
			setMap(new window.google.maps.Map(ref.current, {}));
		}
	}, [ref, map]);
	// [END maps_react_map_component_add_map_hooks]
	// [START maps_react_map_component_options_hook]
	// because React does not do deep comparisons, a custom hook is used
	// see discussion in https://github.com/googlemaps/js-samples/issues/946
	useDeepCompareEffectForMaps(() => {
		if (map) {
			map.setOptions(options);
		}
	}, [map, options]);
	// [END maps_react_map_component_options_hook]
	// [START maps_react_map_component_event_hooks]
	React.useEffect(() => {
		if (map) {
			['click', 'idle'].forEach((eventName) =>
				window.google.maps.event.clearListeners(map, eventName)
			);
			if (onClick) {
				map.addListener('click', onClick);
			}

			if (onIdle) {
				map.addListener('idle', () => onIdle(map));
			}
		}
	}, [map, onClick, onIdle]);
	// [END maps_react_map_component_event_hooks]
	// [START maps_react_map_component_return]
	return (
		<>
			<div ref={ref} style={style} />
			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					// set the map prop on the child component
					// @ts-ignore
					return React.cloneElement(child, { map });
				}
			})}
		</>
	);
	// [END maps_react_map_component_return]
};

const Marker = (options) => {
	const [marker, setMarker] = React.useState();

	React.useEffect(() => {
		if (!marker) {
			var iconSize = new window.google.maps.Size(15, 26);
			var markerImageOver = new window.google.maps.MarkerImage(
				mark,
				iconSize
			);
			setMarker(
				new window.google.maps.Marker({
					icon: mark,
				})
			);
		}

		// remove marker from map on unmount
		return () => {
			if (marker) {
				marker.setMap(null);
			}
		};
	}, [marker]);
	React.useEffect(() => {
		if (marker) {
			marker.setOptions(options);
		}
	}, [marker, options]);
	return null;
};

export default function MapItext_bk_3(props) {
	const { item } = props;
	const options = {
		center: {
			lat: parseFloat(item.latitud),
			lng: parseFloat(item.longitud),
		},
		zoom: item.zoom,
		//zoom: 5
	};

	const [zoom, setZoom] = React.useState(5); // initial zoom
	const [center, setCenter] = React.useState({
		lat: 0,
		lng: 0,
	});

	const onIdle = (m) => {
		//console.log('onIdle');
		setZoom(m.getZoom());
		setCenter(m.getCenter().toJSON());
	};

	const ref = React.useRef(null);
	const [map, setMap] = React.useState();

	React.useEffect(() => {
		if (ref.current && !map) {
			setMap(new window.google.maps.Map(ref.current, {}));
		}
	}, [ref, map]);

	return (
		<div style={{ display: 'flex', height: '348px' }}>
			<Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
				<Map
					center={options.center}
					onIdle={onIdle}
					zoom={options.zoom}
					style={{ flexGrow: '1', height: '100%' }}
				>
					{/* {clicks.map((latLng, i) => (
            <Marker key={i} position={latLng} />
          ))} */}
					{item?.coordinates?.map((location, index) => {
						return (
							<Marker
								key={index}
								position={{
									lat: +location.lat,
									lng: +location.lng,
								}}
							/>
						);
					})}
				</Map>
			</Wrapper>
			{/* Basic form for controlling center and zoom of map. */}
			{/* {form} */}
		</div>
	);
}
