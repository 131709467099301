import React from 'react'
import styles from './SideBarBtn.module.scss'
import { Button} from 'react-bootstrap';
export default function SideBarBtn(props) {
    const {text, func} = props;
    return (
        <Button className={`${styles.btn}`} onClick={func}>
            {text}
        </Button>
    )
}
