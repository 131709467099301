import React from 'react'

export default function SideBar(props) {
    const { children } = props;
    return (
        <div className='py-5'>
            {children}
        </div>
    )
}
