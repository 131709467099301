import React from 'react';
import styles from './NewsnEvents.module.scss';
import Alert from 'modules/website/components/Alert/Alert';
import ContentCard from '../ContentCard/ContentCard';
//import ApiURLs from 'services/BackendApi/BackendApiURLs';
import { useNavigate } from 'react-router-dom';
import SideBarBtn from '../SideBarArticle/SideBarBtn/SideBarBtn';
import { useLastNewsnEventsPage } from 'modules/website/hooks/useLastNewsnEventsPage';
import LoadingColumn from '../LoadingColumn/LoadingColumn';
export default function NewsnEvents(props) {
	const navigate = useNavigate();
	const { isLoading, isError, payload, error } = useLastNewsnEventsPage();
	const { quantity, btnMore, title } = props;
	const handleOnclickMore = () => {
		navigate('/news-events');
	};
	return (
		<div>
			<div className={`${styles.title}`}>
				{title ? title : 'NEWS & EVENTS'}
			</div>
			<div>
				{isLoading && <LoadingColumn quantity={quantity} />}
				{!isLoading && isError && (
					<Alert variant='danger'>{error}</Alert>
				)}
				{!isLoading &&
					!isError &&
					payload &&
					payload.data.slice(0, quantity).map((item, index) => {
						return (
							<ContentCard
								key={index}
								title={item.title}
								image={
									window.location.protocol +
									'//' +
									window.location.hostname +
									item.field_news_image
								}
								nav={'/node/' + item.nid}
							/>
						);
					})}
			</div>
			{btnMore && (
				<div className='d-flex justify-content-center'>
					<SideBarBtn
						text='More News & Events'
						func={handleOnclickMore}
					/>
				</div>
			)}
		</div>
	);
}
