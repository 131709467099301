import React from 'react'
import SideBarBtn from '../SideBarBtn/SideBarBtn';
import styles from './SideBarMain.module.scss';
import { Col, Container, Row } from 'react-bootstrap';
import ContentCard from '../../ContentCard/ContentCard';

import About from 'assets/img/SideBarMainImage/About.jpg';
import Education from 'assets/img/SideBarMainImage/Education.jpg';
import FilmRoundUp from 'assets/img/SideBarMainImage/FilmRoundUp.jpg';
import Services from 'assets/img/SideBarMainImage/Services.jpg';
import WatchNext from '../WatchNext/WatchNext';
import { useNavigate } from 'react-router-dom';

const handleOnclickRandom = () => {
    //console.log('Random Video');
}

/* const options = [
    {
        title: "EDUCATION",
        card:
        {
            title: 'Free K-12 lesson plans aligned with Texas teaching standards',
            image: Education,
            nav: '/education'
        }
    },
    {
        title: 'SERVICES',
        card: {
            title: 'Digitization, licensing, and preservation information',
            image: Services,
            nav: '/services'
        }
    },
    {
        title: 'FILM ROUND-UP',
        card: {
            title: 'A free digitization program for Texas-related films and videotapes',
            image: FilmRoundUp,
            nav: '/round-up'
        }
    },
    {
        title: 'ABOUT',
        card: {
            title: 'Read about our history and programs',
            image: About,
            nav: '/about'
        }

    }

] */

export default function SideBarMain(props) {
    const { options } = props;
    const navigate = useNavigate();
    return (
        <div>
            <Container>
                <Row>
                    <Col lg={12} md={6}>
                        <WatchNext btnMore quantity={6}/>
                    </Col>
                    <Col lg={12} md={6}>
                        <div>
                            {
                                options.map((option, index) => {
                                    return (
                                        <div key={index}>
                                            <div className={`${styles.title}`}>
                                                {option.title}
                                            </div>
                                            <div>
                                                <ContentCard title={option.card.title} image={option.card.image} nav={option.card.nav} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
