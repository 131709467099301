import React, { useEffect } from 'react';
//import { Placeholder } from 'react-bootstrap';
import styles from './News.module.scss';
export default function News(props) {
	const { image, title, children } = props;
	//console.log(title);
	useEffect(() => {
		const handleAnchorClick = (event) => {
			event.preventDefault();
			const targetId = event.currentTarget
				.getAttribute('href')
				.substring(1);
			const targetElement = document.getElementById(targetId);
			if (targetElement) {
				targetElement.scrollIntoView({ behavior: 'smooth' });
			}
		};

		const anchorLinks = document.querySelectorAll('a[href^="#"]');
		anchorLinks.forEach((link) => {
			link.addEventListener('click', handleAnchorClick);
		});

		return () => {
			anchorLinks.forEach((link) => {
				link.removeEventListener('click', handleAnchorClick);
			});
		};
	}, []);
	return (
		<div className={`${styles.news} py-5`}>
			<div className={`${styles.image} d-flex justify-content-center`}>
				<img src={image} alt={title} />
			</div>
			<div className={`${styles.title}`}>{title}</div>
			<div className={`${styles.content}`}>{children}</div>
		</div>
	);
}
