import React from 'react'
import styles from './Comment.module.scss'

export default function Comment(props) {
    const { name, date, body } = props;
    return (
        <div className={`${styles.commentRow}`}>
            <div className={`${styles.column1}`}>
                <div className={`${styles.richmedia_user_comment}`}>
                    {name}
                </div>
                <div className={`${styles.richmedia_date_comment}`}>
                    {date}
                </div>
            </div>
            <div className={`${styles.column2}`}>
                <div className={`${styles.richmedia_body_comment}`}>
                    {body}
                </div>
            </div>
        </div>
    )
}
