import React from 'react'
import { Col, Placeholder, Row } from 'react-bootstrap'
import ContentCardLoading from '../ContentCard/ContentCardLoading/ContentCardLoading'
import styles from './TaxonomyLoading.module.scss'

export default function TaxonomyLoading() {
    return (
        <div>
            <div className={`${styles.title} py-5`}>
                <Placeholder animation="glow">
                    <Placeholder xs={3} />
                </Placeholder>
            </div>
            <div>
                <Row>
                    <Col md={12} lg={6} className="mb-4">
                        <ContentCardLoading />
                    </Col>
                    <Col md={12} lg={6} className="mb-4">
                        <ContentCardLoading />
                    </Col>
                    <Col md={12} lg={6} className="mb-4">
                        <ContentCardLoading />
                    </Col>
                    <Col md={12} lg={6} className="mb-4">
                        <ContentCardLoading />
                    </Col>
                    <Col md={12} lg={6} className="mb-4">
                        <ContentCardLoading />
                    </Col>
                    <Col md={12} lg={6} className="mb-4">
                        <ContentCardLoading />
                    </Col>
                    <Col md={12} lg={6} className="mb-4">
                        <ContentCardLoading />
                    </Col>
                    <Col md={12} lg={6} className="mb-4">
                        <ContentCardLoading />
                    </Col>
                    <Col md={12} lg={6} className="mb-4">
                        <ContentCardLoading />
                    </Col>
                    <Col md={12} lg={6} className="mb-4">
                        <ContentCardLoading />
                    </Col>
                </Row>
            </div>
        </div>
    )
}
