import React, { useEffect, useState } from 'react';
import styles from './EducationView.module.scss';
import NewsnEventsViewLoading from '../News&EventsView/News&EventsViewLoading/News&EventsViewLoading';
import Alert from 'modules/website/components/Alert/Alert';
import { useAliasNodePage } from 'modules/website/hooks/useAliasNodePage';
import { useEducationPagination } from 'modules/website/hooks/useEducationOption';
import { Col, Row } from 'react-bootstrap';
import ContentCard from 'modules/website/components/ContentCard/ContentCard';
import Pagination from 'modules/website/components/Pagination/Pagination';
import ApiURLs from 'services/BackendApi/BackendApiURLs';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function EducationView() {
	const {
		isLoading: isLoadingInfo,
		isError: isErrorInfo,
		payload: payloadInfo,
		error: errorInfo,
	} = useAliasNodePage({
		alias: 'education',
	});

	/* useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []); */

	const navigate = useNavigate();

	const [page, setPage] = useState(1);
	const { isLoading, isError, payload, error } = useEducationPagination({
		page: page,
		respp: 10,
		sortby: 'isodate',
	});
	const url = new URL(window.location.href);
	const pageUrl = url.searchParams.get('page');

	useEffect(() => {
		setPage(pageUrl);
	}, [pageUrl]);

	return (
		<div>
			{isLoadingInfo && <NewsnEventsViewLoading />}
			{isErrorInfo && <Alert variant='danger'>{errorInfo}</Alert>}
			{!isLoadingInfo && !isErrorInfo && payloadInfo && (
				<>
					<div className={`${styles.title} py-5`}>
						{payloadInfo.title[0].value}
					</div>
					<div className={`${styles.description}`}>
						<div
							dangerouslySetInnerHTML={{
								__html: payloadInfo.body[0].processed,
							}}
						/>
					</div>
					<div className={`${styles.content}`}>
						<Row>
							{payload?.records?.record &&
								(Array.isArray(payload?.records?.record) ? (
									payload?.records?.record?.map(
										(item, index) => {
											return (
												<Col
													key={index}
													md={12}
													lg={6}
													className='mb-4'
												>
													<ContentCard
														key={index}
														title={item.title}
														image={
															ApiURLs.baseURL +
															item.thumbnail
														}
														description={item.blurb}
														nav={item.id}
													/>
												</Col>
											);
										}
									)
								) : (
									<Col md={12} lg={6} className='mb-4'>
										<ContentCard
											title={
												payload?.records?.record.title
											}
											image={
												ApiURLs.baseURL +
												payload?.records?.record
													.thumbnail
											}
											description={
												payload?.records?.record.blurb
											}
											nav={payload?.records?.record.id}
										/>
									</Col>
								))}
						</Row>
						<Pagination result_header={payload?.result_header} />
					</div>
					<div>
						<div>In partnership with:</div>
						<a
							href='https://gov.texas.gov/film/page/moving_image_archive'
							target='_blank'
							rel='noreferrer'
						>
							<img
								src='https://texasarchive.org/sites/default/files/images_end/test/TFCLogo_forWeb.png'
								alt='Texas film'
							/>
						</a>
					</div>
				</>
			)}
		</div>
	);
}
