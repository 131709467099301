import SideBarBtn from 'modules/website/components/SideBarArticle/SideBarBtn/SideBarBtn';
import React from 'react';
import { Col, Container, Placeholder, Row } from 'react-bootstrap';
import styles from './HomeView.module.scss';
import NewsnEvents from 'modules/website/components/NewsnEvents/NewsnEvents';
import { useNavigate } from 'react-router-dom';
import WebExhibits from 'modules/website/components/WebExhibits/WebExhibits';
import GlifosRichMediaProvider from 'modules/glifos-rich-media/components/GlifosRichMediaProvider/GlifosRichMediaProvider';
import GlifosRichMediaPlayer from 'modules/glifos-rich-media/components/GlifosRichMediaPlayer/GlifosRichMediaPlayer';
import Alert from 'modules/website/components/Alert/Alert';
import { useVideoInfoPage } from 'modules/website/hooks/useVideoInfoPage';
import GlifosRichMediaPlayerIOS_test from 'modules/glifos-rich-media/components/GlifosRichMediaPlayer/GlifosRichMediaIOS_test';
//import GlifosRichMediaPlayer from 'modules/glifos-rich-media/components/GlifosRichMediaPlayer/GlifosRichMediaPlayer_test';
export default function HomeView(props) {
	const { video, nw_label, we_label } = props;
	const navigate = useNavigate();

	const alias = video.replace('_480x360', '');

	const handleOnclickMore = () => {
		navigate(alias);
	}

	const { isLoading, isError, payload, error } = useVideoInfoPage({
		alias: alias.replace('/', ''),
		stylesheet: 'DefaultJSON',
	});

	return (
		<div className='d-flex flex-column justify-content-center align-items-center'>
			<GlifosRichMediaProvider>
				<Container className='py-5'>
					{
						isLoading && (
							<Placeholder animation="wave" className={`${styles.videoplaceholder}`} >
								<Placeholder xs={12} size="lg" />
							</Placeholder>
						)
					}
					{
						isError && <Alert variant="danger">{error}</Alert>
					}
					{
						!isLoading && !isError && payload && (
							<Row>
								<Col md={12}>
									<GlifosRichMediaPlayerIOS_test src={payload?.metadata?.video?.src} thumbnail={payload?.metadata?.video?.thumbnail} />
								</Col>
								<Col md={12} lg={8} className={`${styles.title}`}>
									{payload?.metadata?.title}
								</Col>
								<Col md={12} lg={4} className={`${styles.exploreBtn}`}>
									<SideBarBtn text="Explore this video" func={handleOnclickMore} />
								</Col>
							</Row>
						)
					}
				</Container>
			</GlifosRichMediaProvider>
			<Container className={`${styles.options}`}>
				<Row>
					<Col lg={6} md={6}>
						<NewsnEvents quantity={4} btnMore title={nw_label} />
					</Col>
					<Col lg={6} md={6}>
						<WebExhibits quantity={4} btnMore title={we_label} />
					</Col>
				</Row>
			</Container>
		</div>
	);
}
