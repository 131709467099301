import React from 'react';
import Navbar from '../BasicLayout/Navbar/Navbar';

import styles from './SideBarLayout.module.scss';
import Footer from '../BasicLayout/Footer/Footer';
import { Col, Container, Row } from 'react-bootstrap';

export default function SideBarLayout(props) {
    const { children, rightBar, leftBar } = props;
    return (
        <div className='d-flex flex-column vh-100 vw-100 overflow-auto'>
            <a
                href='#main'
                className={`d-flex flex-row w-100 justify-content-center ${styles.skip_main_content}`}>
                Skip to main content
            </a>
            <Navbar />
            <main id='main' className={`d-flex flex-column flex-grow-1 ${styles.main}`} >
                <Container>
                    <Row className={`${styles.main_row}`}>

                        {
                            leftBar && (
                                <Col lg={4} md={12}>
                                    <div className={`${styles.left_bar}`}>
                                        {props.leftBar}
                                    </div>
                                </Col>
                            )
                        }

                        <Col lg={leftBar && !rightBar ? 8 : !leftBar && rightBar ? 8 : leftBar && rightBar && 4} md={12}>
                            {children}
                        </Col>

                        {
                            rightBar && (
                                <Col lg={4} md={12}>
                                    <div className={`${styles.right_bar}`}>
                                        {props.rightBar}
                                    </div>
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </main>
            <Footer />
        </div>
    );
}
