import React from 'react';
import styles from '../style/BrowseBy.module.scss';
import { Link } from 'react-router-dom';
import { useNewsnEventsOption } from 'modules/website/hooks/useNewsnEventsOption';
import BrowseByLoading from '../BrowseByLoading/BrowseByLoading';
export default function BrowseByCategory() {
	const { isLoading, isError, payload, error } = useNewsnEventsOption({
		type: 'category',
	});
	return (
		<div className={`py-2 ${styles.browseby}`}>
			<div className={`${styles.title}`}>Browse by Category</div>
			<div className={styles.links}>
				{isLoading && <BrowseByLoading />}
				{isError && <div>{error}</div>}
				{payload &&
					Object.values(payload).map((item, index) => (
						<div className={styles.option} key={index}>
							<Link
								key={index}
								className={styles.link}
								to={`/taxonomy/term/${item.tid}`}
							>
								{item.name}
							</Link>
						</div>
					))}
			</div>
		</div>
	);
}
