import ContentCardLoading from 'modules/website/components/ContentCard/ContentCardLoading/ContentCardLoading'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

export default function VideosViewLoading() {
    return (
        <div>
            <Row>
                <Col md={12} lg={6}>
                    <ContentCardLoading />
                </Col>
                <Col md={12} lg={6}>
                    <ContentCardLoading />
                </Col>
                <Col md={12} lg={6}>
                    <ContentCardLoading />
                </Col>
                <Col md={12} lg={6}>
                    <ContentCardLoading />
                </Col>
                <Col md={12} lg={6}>
                    <ContentCardLoading />
                </Col>
                <Col md={12} lg={6}>
                    <ContentCardLoading />
                </Col>
                <Col md={12} lg={6}>
                    <ContentCardLoading />
                </Col>
                <Col md={12} lg={6}>
                    <ContentCardLoading />
                </Col>
                <Col md={12} lg={6}>
                    <ContentCardLoading />
                </Col>
                <Col md={12} lg={6}>
                    <ContentCardLoading />
                </Col>
                <Col md={12} lg={6}>
                    <ContentCardLoading />
                </Col>
                <Col md={12} lg={6}>
                    <ContentCardLoading />
                </Col>
            </Row>
        </div>
    )
}
