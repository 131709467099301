import React, { Suspense, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import SideBar from '../SideBar/SideBar';
import BrowseByTopic from '../BrowseByTopic/BrowseByTopic';
import BrowseByGrade from '../BrowseByGrade/BrowseByGrade';
import OtherReources from '../OtherReources/OtherReources';
import { useTaxonomyTitle } from 'modules/website/hooks/useTaxonomyPage';
import BrowseByYear from '../BrowseByYear/BrowseByYear';
import BrowseByCategory from '../BrowseByCategory/BrowseByCategory';
import BrowseBy from '../BrowseBy/BrowseBy';

export default function TaxonomySideBar() {
    const location = useLocation();
    const tid = location.pathname.replace('/taxonomy/term/', '');
    const [sideBarTax, setSideBar] = useState(null);

    const { isLoading, isError, payload, error } = useTaxonomyTitle({
        tid: tid,
    });

    useEffect(() => {
        if (!isLoading && payload) {
            const vocabulary = payload[0].vid
            switch (vocabulary) {
                case "lesson_categories":
                    setSideBar(
                        <SideBar>
                            <BrowseByTopic />
                            <BrowseByGrade />
                            <OtherReources />
                        </SideBar>
                    )
                    break;
                case "news_categories":
                    setSideBar(
                        <SideBar>
                            <BrowseByYear />
                            <BrowseByCategory />
                        </SideBar>
                    )
                    break;
                case 'news_years':
                    setSideBar(
                        <SideBar>
                            <BrowseByYear />
                            <BrowseByCategory />
                        </SideBar>
                    )
                    break;
                case 'location_tami':
                    setSideBar(
                        <SideBar>
                            <BrowseBy />
                        </SideBar>
                    )
                    break;
                case "category_video_tami":
                    setSideBar(
                        <SideBar>
                            <BrowseBy />
                        </SideBar>
                    )
                    break;
                default:
                    // Manejo de tipo desconocido o sin asignar
                    setSideBar(
                        <SideBar>
                        </SideBar>
                    )
                    break;
            }
        }

    }, [payload])


    return (
        <Suspense fallback={<div>Loading....</div>}> {sideBarTax}</Suspense>
    )
}
