import React from 'react';
import Navbar from './Navbar/Navbar';

import styles from './BasicLayout.module.scss';
import Footer from './Footer/Footer';

export default function BasicLayout(props) {
	const { children } = props;
	return (
		<div className='d-flex flex-column vh-100 vw-100 overflow-auto'>
			<a
				href='#main'
				className={`d-flex flex-row w-100 justify-content-center ${styles.skip_main_content}`}>
				Skip to main content
			</a>
			<Navbar />
			<main id='main' className='d-flex flex-column flex-grow-1'>
				{children}
			</main>
			<Footer />
		</div>
	);
}
