import React from 'react'
import styles from './ContentCardLoading.module.scss';
import { Placeholder } from 'react-bootstrap';
export default function ContentCardLoading() {
    return (
        <div className={`${styles.card}`}>
            <div className={`${styles.image}`}>
                <Placeholder animation="wave" >
                    <Placeholder className={`${styles.placeimage}`} />
                </Placeholder>
            </div>
            <div className={`${styles.content}`}>
                <div className={`${styles.title}`}>
                    <Placeholder className={`${styles.placetitle}`} xs={12} size="lg" />
                </div>
                <div className={`${styles.description}`}>
                    <Placeholder as="p" animation="wave">
                        <Placeholder className={`${styles.placetitle}`} xs={3} size="sm" /> <Placeholder className={`${styles.placetitle}`} xs={8} size="sm" />
                        <Placeholder className={`${styles.placetitle}`} xs={4} size="sm" /> <Placeholder className={`${styles.placetitle}`} xs={7} size="sm" />
                    </Placeholder>
                </div>
            </div>
        </div>
    )
}
