import GlifosRichMediaPlayer from 'modules/glifos-rich-media/components/GlifosRichMediaPlayer/GlifosRichMediaPlayer'
import React from 'react'
import ReactPlayer from 'react-player'

export default function TestView() {
  const src = 'https://texasarchive-flash.streamguys1.com/vod/mp4:2016_05795_480x360/manifest.mpd'
  return (
    <div>
        <ReactPlayer url={src} controls={true} />
    </div>
  )
}
