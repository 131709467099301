import React from 'react';
import CommentForm from '../CommentForm/CommentForm';
import Comment from '../Comment/Comment';
import styles from './CommentsOption.module.scss';
import { useParams } from 'react-router-dom';
import { useCommentsNodePage } from 'modules/website/hooks/useCommentsNodePage';
import apiService from 'services/BackendApi/BackendApi';

export default function CommentsOption(props) {
	const { nid } = props;
	let { alias } = useParams();
	const [comments, setComments] = React.useState([]);
	//console.log(alias, nid);

	React.useEffect(() => {
		updateComments();
	}, [alias]);

	const updateComments = async () => {
		try {
			let request = 'GetComments';
			if (alias === undefined) {
				request = 'GetCommentsNID';
			}

			const response = await apiService[request]({
				urlParams: {
					alias: alias,
					nid: nid,
				},
			});

			if (response.data) {
				// Actualiza el estado de los comentarios con la nueva lista
				setComments(response.data.comments);
			}
		} catch (error) {
			console.error('Error al actualizar los comentarios:', error);
		}
	};

	/* const { isLoading, isError, payload, error } = useCommentsNodePage({
        alias: alias,
    }); */

	return (
		<div>
			{comments && (
				<div className={`${styles.comments}`}>
					{comments.map((c, index) => {
						return (
							<Comment
								name={c.user_name}
								date={c.date_text}
								body={c.body}
								key={index}
							/>
						);
					})}
				</div>
			)}
			<CommentForm nid={nid} updateComments={updateComments} />
		</div>
	);
}
