import { useEffect, useState } from 'react';
import apiService from 'services/BackendApi/BackendApi';

export function useCategoryTreePage(props) {
	const { category, page, respp, location,tid } = props;
	const [pageContent, setPageContent] = useState({
		isLoading: false,
		isError: false,
		payload: null,
		error: null,
	});
	useEffect(() => {
		setPageContent((prevPageContent) => ({
			...prevPageContent,
			isLoading: true,
		}));
		apiService
			.GetCategoryPagination(
				{
					params: {
						id: tid,
						category: category,
						location: location,
						page: page,
						respp: respp,
						sortby: 'sorttitle'
                        //contenttype: 'category_video_tami',
					},
				}
			)
			.then((response) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: false,
					payload: {
						...response.data,
					},
				}));
			})
			.catch((error) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: true,
					error: error?.message,
				}));
			})
			.finally(() => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isLoading: false,
				}));
			});
	},[category,page]);

	return pageContent;

}

export function useTaxonomyTitle(props) {
	const {tid} = props;
    const [pageContent, setPageContent] = useState({
        isLoading: false,
        isError: false,
        payload: null,
        error: null,
    });
    useEffect(() => {
		setPageContent((prevPageContent) => ({
			...prevPageContent,
			isLoading: true,
		}));
		apiService
			.GetTaxonomyTitle(
                {
                    urlParams: {
                        tid: tid,
                    }
                }
            )
			.then((response) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: false,
					payload: {
						...response.data,
					},
				}));
			})
			.catch((error) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: true,
					error: error?.message,
				}));
			})
			.finally(() => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isLoading: false,
				}));
			});
	}, [tid]);

	return pageContent;
}
