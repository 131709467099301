import React from "react";

export default function PlayButton(props){
    const {stylesheet,videostate} = props;
    switch (videostate){
        case "stopped":case "paused":{
            return <svg height="100%" version="1.0" viewBox="0 0 36 36" width="100%">
                <use className={stylesheet.playicon}></use>
                <path className={stylesheet.iconfill} d="M 12,26 18.5,22 18.5,14 12,10 z M 18.5,22 25,18 25,18 18.5,14 z" id="gp-b-01"></path>
            </svg>;

        }
        case "playing":{
            return <svg height="100%" version="1.0" viewBox="0 0 36 36" width="100%">
                <use className={stylesheet.playicon}></use>
                <path className={stylesheet.iconfill} d="M 12,26 16,26 16,10 12,10 z M 21,26 25,26 25,10 21,10 z" id="gp-b-02"></path>
            </svg>;
        }
    }
    return "";
}