
export function timeToSeconds(time){
    time=(typeof(time)!=="string")?time.toString():time;
    let a = time.split(':'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    let seconds =  0;
    let c = 1;
    for (let x=a.length-1;x>=0;x--){
        c = a.length-x;
        switch (c){
            case 1:{
                seconds+=a[x]*1;
            }break;
            case 2:{
                seconds+=a[x]*60;
            }break;
            case 3:{
                seconds+=a[x]*60*60;
            }break;
        }
    } 
    //console.log('time to seconds',time,a,seconds);
    return seconds;
}
export function intToStringLeadingZeros(num,digits){
    let numString = num.toString();
    while (numString.length<digits){
        numString="0"+numString;
    }

    return numString;
}
export function secondsToTime(amt){
    let minutes = Math.floor(amt/60);
    let seconds = Math.floor(amt % 60);
    let hours = 0;
    let result ="";
    if (minutes>=60){
        hours = Math.floor(minutes/60);
        minutes = minutes % 60;
    }
    result = (hours>0)?(intToStringLeadingZeros(hours,2)+":"):"";
    result += ((minutes>0)?intToStringLeadingZeros(minutes,2):"00")+":";
    result += intToStringLeadingZeros(seconds,2);
    return result;
}