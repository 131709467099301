// La conexión la diferencia dependiendo el ambiente en el que esté
const ApiHostName =
	window.location.hostname.indexOf('texasarchive.org') >= 0
		? window.location.hostname + '/api'
		: window.location.hostname.indexOf('local') >= 0
		? window.location.hostname + '/api'
		: 'api.tami.dev.glifos.net';

//console.log('ApiHostName:' + ApiHostName);
const ApiURLs = {
	publicFilesURL: 'https://' + ApiHostName + '/v1/',
	baseURL: window.location.protocol + '//' + ApiHostName,
	
	//para pruebas en telefono
		/* publicFilesURL: 'https://' + '192.168.31.82' + '/v1/',
	baseURL: window.location.protocol + '//' + '192.168.31.82/api', */
	
	GetPageInfo: {
		href: '/glifos/:alias',
		method: 'get',
		urlParams: ['alias'],
		params: ['_format'],
	},
	GetNews: {
		href: '/glifos/api/all/news',
		method: 'get',
		urlParams: [],
		params: [],
	},
	GetLastNews: {
		href: '/glifos/api/last/news',
		method: 'get',
		urlParams: [],
		params: [],
	},
	GetWebExhibits: {
		href: '/glifos/api/all/web_exhibits',
		method: 'get',
		urlParams: [],
		params: [],
	},
	GetLastWebExhibits: {
		href: '/glifos/api/last/web_exhibits',
		method: 'get',
		urlParams: [],
		params: [],
	},
	GetNodeInfo: {
		href: '/glifos/node/:nid',
		method: 'get',
		urlParams: ['nid'],
		params: ['_format'],
	},
	GetAliasNodeInfo: {
		href: '/glifos/:alias',
		method: 'get',
		urlParams: ['alias'],
		params: ['_format'],
	},
	GetVideoInfo: {
		href: '/v1/richmedia/metadata/:alias/:stylesheet',
		method: 'get',
		urlParams: ['alias', 'stylesheet'],
		params: [],
	},
	GetEducationOptions: {
		href: '/glifos/api/education/:type',
		method: 'get',
		urlParams: ['type'],
		params: [],
	},
	GetNewsOptions: {
		href: '/glifos/api/news/:type',
		method: 'get',
		urlParams: ['type'],
		params: [],
	},
	GetTaxonomyTitle: {
		href: '/glifos/api/taxonomy/:tid',
		method: 'get',
		urlParams: ['tid'],
		params: [],
	},
	GetWatchNextOptions: {
		href: '/v1/search/query',
		method: 'get',
	},
	GetRandomVideo: {
		href: '/v1/richmedia/randomvideo',
		method: 'get',
		params: [],
	},
	GetComments: {
		href: '/v1/richmedia/comments/:alias',
		method: 'get',
		urlParams: ['alias'],
		params: [],
	},
	GetCommentsNID: {
		href: '/v1/richmedia/comments-nid/:nid',
		method: 'get',
		urlParams: ['nid'],
		params: [],
	},
	GetBrowseBy: {
		href: '/v1/richmedia/browseby',
		method: 'get',
		params: [],
	},
	GetCategoryContent: {
		href: '/v1/search/query',
		method: 'get',
		params: ['category'],
	},
	GetCategoryPagination: {
		href: '/v1/search/taxonomyterm',
		method: 'get',
		params: ['page', 'respp'],
	},
	GetPagination: {
		href: '/v1/search/query',
		method: 'get',
		params: [],
	},
	GetTaxonomyInfo: {
		href: '/v1/search/taxonomyterm',
		method: 'get',
		params: [],
	},
	GetExtraDataEducation: {
		href: '/v1/educationlesson/extradata/:nid',
		method: 'get'
	},
	GetOtherResource: {
		href: '/v1/website/otherresource',
		method: 'get',
		params: [],
	},
};
//console.log(ApiURLs);
export default ApiURLs;
