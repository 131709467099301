import React, { useEffect } from 'react';
import styles from './News&EventsView.module.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { useNewsnEventsPage } from 'modules/website/hooks/useNewsnEventsPage';
import ApiURLs from 'services/BackendApi/BackendApiURLs';
import Alert from 'modules/website/components/Alert/Alert';
import ContentCard from 'modules/website/components/ContentCard/ContentCard';
import NewsnEventsViewLoading from './News&EventsViewLoading/News&EventsViewLoading';
import { useNavigate } from 'react-router-dom';
import { useAliasNodePage } from 'modules/website/hooks/useAliasNodePage';
import ReactGA from 'react-ga4';

export default function NewsnEventsView() {
	const { isLoading, isError, payload, error } = useNewsnEventsPage();

	//console.log('payload', payload);

	/* useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []); */

	const {
		isLoading: isLoadingInfo,
		isError: isErrorInfo,
		payload: payloadInfo,
		error: errorInfo,
	} = useAliasNodePage({
		alias: 'news-events',
	});
	//TODO: ver como funciona que jale los contenidos como estan arriba
	const navigate = useNavigate();
	return (
		<div>
			{isLoading && isLoadingInfo && <NewsnEventsViewLoading />}
			{isError && isErrorInfo && <Alert variant='danger'>{error}</Alert>}
			{!isLoading &&
				!isError &&
				!isLoadingInfo &&
				!isErrorInfo &&
				payload &&
				payloadInfo && (
					<>
						<div className={`${styles.title} py-5`}>
							{payloadInfo.title[0].value}
						</div>
						<div className={`${styles.description}`}>
							<div
								dangerouslySetInnerHTML={{
									__html: payloadInfo.body[0].processed,
								}}
							/>
						</div>
						<div>
							<Container>
								<Row>
									{payload?.data?.records?.record?.map(
										(item, index) => {
											return (
												<Col md={6} lg={6} key={index}>
													<ContentCard
														key={index}
														title={item.title}
														image={
															ApiURLs.baseURL +
															item.thumbnail
														}
														nav={item.id}
													/>
												</Col>
											);
										}
									)}
								</Row>
								{/* {payload?.solr_content?.records?.record && (
									(!Array.isArray(
										payload?.solr_content?.records?.record
									) ? (
										<Row>
											<Col md={6} lg={6} >
												<ContentCard
													title={
														payload?.solr_content?.records
															?.record.title
													}
													image={
														ApiURLs.baseURL +
														payload?.solr_content?.records
															?.record.thumbnail
													}
													description={
														payload?.solr_content?.records
															?.record.blurb
													}
													nav={
														payload?.solr_content?.records
															?.record.id
													}
												/>
											</Col>
										</Row>
									) : (
										
									)
									))} */}


							</Container>
						</div>
					</>
				)
			}
		</div >
	);
}
