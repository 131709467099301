// import BasicLayout from 'common/layouts/BasicLayout/BasicLayout';
import SideBarLayout from 'common/layouts/SideBarLayout/SideBarLayout';
import { importComponent } from 'common/loaders/importComponent';
import GlifosMetadataLoading from 'modules/glifos-rich-media/components/GLifosMetadata/GlifosMetadataLoading/GlifosMetadataLoading';
// import NewsnEvents from 'modules/website/components/NewsnEvents/NewsnEvents';
// import SideBar from 'modules/website/components/SideBars/SideBar/SideBar';
import SideBarMain from 'modules/website/components/SideBars/SideBarMain/SideBarMain';
// import WatchNext from 'modules/website/components/SideBars/WatchNext/WatchNext';
/* import SideBarDocument from 'modules/glifos-rich-media/components/SideBarDocument/SideBarDocument';
import SideBarVideo from 'modules/glifos-rich-media/components/SideBarVideo/SideBarVideo'; */
import { useAliasNodePage } from 'modules/website/hooks/useAliasNodePage';
import React, { Suspense, useEffect, useState } from 'react';
import { ComponentMap } from 'routes/ComponentMap';

export default function MainPageView() {
	/* const [ViewLoad, setViewLoad] = useState(null); */
	const [leftBar] = useState(null);
	const [rightBar, setRightBar] = useState(null);
	/* const [title, setTitle] = useState(null);
    const [breadC, setBreadC] = useState(null);
    const [description, setDescription] = useState(null); */
	const [component, setComponent] = useState(null);
	const { isLoading, payload } = useAliasNodePage({
		alias: 'mainpage',
	});

	/* useEffect(() => {
		ReactGA.pageview(location.pathname + location.search);
	}, [location.pathname, location.search]); */

	useEffect(() => {
		if (!isLoading && payload) {
			const type = payload.type[0].target_id;

			const options = [
				{
					title: payload.field_mp_education_label[0].value,
					card: {
						title: payload.field_mp_education_title[0].value,
						image: payload.field_eductaion_image[0].url,
						nav: '/education',
					},
				},
				{
					title: payload.field_mp_services_label[0].value,
					card: {
						title: payload.field_mp_services_title[0].value,
						image: payload.field_mp_services_image[0].url,
						nav: '/services',
					},
				},
				{
					title: payload.field_mp_fru_label[0].value,
					card: {
						title: payload.field_mp_fru_title[0].value,
						image: payload.field_mp_fru_image[0].url,
						nav: '/round-up',
					},
				},
				{
					title: payload.field_mp_about_label[0].value,
					card: {
						title: payload.field_mp_about_title[0].value,
						image: payload.field_mp_about_image[0].url,
						nav: '/about',
					},
				},
			];

			switch (type) {
				case 'main_page':
					//get from ComponentMap where type is the same
					setComponent(
						ComponentMap.find(
							(componente) => componente.type === type
						)
					);
					setRightBar(<SideBarMain options={options} />);

					break;
				default:
					// Manejo de tipo desconocido o sin asignar
					break;
			}
		}
		// eslint-disable-next-line
	}, [payload]);

	const ViewComponent = component ? importComponent(component.c) : null;

	return component && component.l === 'right' ? (
		<SideBarLayout leftBar={leftBar} rightBar={rightBar}>
			<Suspense fallback={<GlifosMetadataLoading />}>
				<ViewComponent
					video={payload.field_video_main_page[0].value}
					we_label={payload.field_mp_we_label[0].value}
					nw_label={payload.field_mp_nw_label[0].value}
				/>
			</Suspense>
		</SideBarLayout>
	) : (
		<></>
	);
}
