import React from 'react'
import styles from './SocialMedia.module.scss'

export default function SocialMedia(props) {
    const { link, color } = props
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
        <div className={`rounded-circle ${styles.option}`} style={{ background: color }} onClick={() => openInNewTab(link)}>
            <props.icon className={`${styles.icon}`} />
        </div>
    )
}
