import ContentCardLoading from 'modules/website/components/ContentCard/ContentCardLoading/ContentCardLoading'
import React from 'react'
import { Col, Container, Placeholder, Row } from 'react-bootstrap'
import styles from './WebExhibistsLoading.module.scss'

export default function WebExhibistsLoading() {
    var quantity = 8;
    return (
        <div>
            <div className={`${styles.title} py-5`}>
                <Placeholder as="p" animation="wave">
                    <Placeholder xs={4} size="lg" />
                </Placeholder>
            </div>
            <div className={`${styles.description}`}>
                <Placeholder as="p" animation="wave">
                    <Placeholder xs={4} /> <Placeholder xs={2} /> <Placeholder xs={3} /> <Placeholder xs={3} />
                </Placeholder>
            </div>
            <Container>
                <Row>
                    {
                        [...Array(quantity)].map((item, index) => {
                            return (
                                <Col md={12} lg={6}>
                                    <ContentCardLoading key={index} />
                                </Col>
                            )
                        }
                        )
                    }
                </Row>
            </Container>
        </div>
    )
}
