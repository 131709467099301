import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, Overlay, Tooltip } from 'react-bootstrap';
import styles from './ShareModal.module.scss';
import { Facebook, File, Files, Linkedin, Reddit, Twitter, Whatsapp, Pinterest } from 'react-bootstrap-icons';
import { useParams } from 'react-router-dom';
import TwitterX from 'modules/website/components/SocialOptions/TwitterX';

export default function ShareModal(props) {
    const { alias } = useParams();
    const { show, handleClose, start, end , title } = props;
    const [link, setLink] = useState(window.location.href);

    const [showOverLay, setShowOverlay] = useState(false);
    const target = useRef(null);
    const [msg, setMsg] = useState("");

    const [iframe, setIframe] = useState('');

    useEffect(() => {
        setIframe(
            `<iframe width="485" height="385" src="https://texasarchive.org/glifosrichmedia/build?id=${alias}_480x360&embed" frameborder="0">\n</iframe>`
        )

    });

    const SocialMediaOptions = [
        {
            title: 'Facebook',
            icon: Facebook,
            link: 'http://www.facebook.com/sharer.php?u=' + encodeURIComponent(link) + '$&t=' + encodeURIComponent(title),
            color: '#3b5998'
        },

        {
            title: 'Twitter',
            icon: TwitterX,
            link: 'http://twitter.com/intent/tweet?text=' + encodeURIComponent(title) + '&url=' + encodeURIComponent(link),
            color: '#000'
        },
        {
            title: 'Pinterest',
            icon: Pinterest,
            link: 'https://www.pinterest.com/pin/create/button/?url=' + encodeURIComponent(link) + '&description=' + title ? encodeURIComponent(title.replace(' ', '+')) : '' + '&ref=os',
            color: '#bd081c',
            blank: true
        },

        {
            title: 'Reddit',
            icon: Reddit,
            link: 'https://www.reddit.com/submit?title=' + title ? encodeURIComponent(title.replace(' ', '+')) : ''+ '&url=' + encodeURIComponent(link),
            //color: '#cee3f8'
            color: '#FF5700',
            blank: true
        },
        {
            title: 'Linkedin',
            icon: Linkedin,
            link: 'https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(link),
            color: '#0072b1 '
        },
        {
            title: 'Whatsapp',
            icon: Whatsapp,
            link: 'https://web.whatsapp.com/send?text=' + encodeURIComponent(link),
            color: '#25d366',
            blank: true
        },

    ]

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const b = urlParams.get('b');
        const e = urlParams.get('e');
        if (b && e) {
            if (start > end) {
                let link = window.location.href;
                link = link.replace("?b=" + b + "&e=" + e, "?b=" + start)
                setLink(link)
            } else {
                let link = window.location.href;
                link = link.replace("?b=" + b + "&e=" + e, "?b=" + start + "&e=" + end)
                setLink(link)
            }
        } else if (b && !e) {
            if (start < end) {
                let link = window.location.href;
                link = link.replace("?b=" + b, "?b=" + start + "&e=" + end)
                setLink(link)
            } else {
                let link = window.location.href;
                link = link.replace("?b=" + b, "?b=" + start)
                setLink(link)
            }
        } else {
            const link = window.location.href;
            if (start > end) {
                setLink(link + "?b=" + start)
            } else {

                setLink(link + "?b=" + start + "&e=" + end)
            }
        }
    }, [start, end])

    const inputRef = useRef(null);

    const copyToClipboard = () => {
        if (inputRef.current) {
            //inputRef.current.select();
            navigator.clipboard.writeText(inputRef.current.value)
                .then(() => {
                    //console.log('Copied to clipboard');
                    setMsg("Copied to clipboard");
                    setShowOverlay(true);
                    setTimeout(() => {
                        //console.log("set show to false")
                        setShowOverlay(false);

                    }, 1000)

                })
                .catch((error) => {
                    console.error('Error copying to clipboard:', error);
                    setShowOverlay(true);
                    setMsg("Error copying to clipboard");
                    setTimeout(() => {
                        console.log("set show to false")
                        setShowOverlay(false);

                    }, 1000)
                });
        }
    };

    const openPopup = (url) => {
        window.open(url, 'popup', 'width=800,height=600');
    };

    const openTargetBlank= (url) => {
        window.open(url, '_blank');
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton />
            <Modal.Body>
                <div className={`${styles.shareLink}`}>
                    <div className={`${styles.title}`}>
                        Share Link
                    </div>
                    <div className={`${styles.value}`}>
                        <input id='input-link' ref={inputRef} type="text" defaultValue={link} />
                        <Button className={`${styles.btn}`} ref={target} onClick={copyToClipboard} >
                            <Files />
                        </Button>
                        <Overlay target={target.current} show={showOverLay} placement="top">
                            {(props) => (
                                <Tooltip id="overlay-example" {...props}>
                                    {msg}
                                </Tooltip>
                            )}
                        </Overlay>
                    </div>
                </div>
                <div className={`${styles.socialLinks}`}>
                    <div className={`${styles.title}`}>
                        Social
                    </div>
                    <div className={`${styles.value}`}>
                        {SocialMediaOptions.map((item, index) => {
                            /* return (
                                <div className={`${styles.btn}`} style={{ background: item.color }} title={"Share on " + item.title} key={index}>
                                    <item.icon className={`${styles.icon}`}  width="30" height="30"  />
                                </div>
                            ) */
                            return (
                                <div className={`${styles.btn}`} title={"Share on " + item.title} key={index} style={{ background: item.color }}>
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            item.blank ? openTargetBlank(item.link) : openPopup(item.link);
                                        }}
                                    >
                                         <item.icon className={`${styles.icon}`}  width="30" height="30"  />
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={`${styles.embedCode}`}>
                    <div className={`${styles.title}`}>
                        Embed Code
                    </div>
                    <div className={`${styles.value}`}>
                        <textarea id='input-link' type="text" value={iframe} />
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}
