import React from 'react'
import styles from './WebExhibits.module.scss';
import SideBarBtn from '../SideBarArticle/SideBarBtn/SideBarBtn';
import { useNavigate } from 'react-router-dom';
import { useLastWebExhibitsPage } from 'modules/website/hooks/useLastWebExhibitsPage';
import ContentCard from '../ContentCard/ContentCard';
import ApiURLs from 'services/BackendApi/BackendApiURLs';
import LoadingColumn from '../LoadingColumn/LoadingColumn';
import { Windows } from 'react-bootstrap-icons';
export default function WebExhibits(props) {
    const { quantity, btnMore, title } = props;
    const { isLoading, isError, payload, error } = useLastWebExhibitsPage();
    const navigate = useNavigate();
    const handleOnclickMore = () => {
        navigate('/web-exhibits');
    }
    //console.log(payload);
    return (
        <>
            <div className={`${styles.title}`}>
            {title ? (
                    title
                ) : (
                    'WEB EXHIBITS'
                )}
            </div>
            <div>
                {isLoading && <LoadingColumn quantity={4}/>}
                {!isLoading && !isError && payload && (
                    payload.data.slice(0, quantity).map((item, index) => {
                        return (
                            <ContentCard 
                            key={index} title={item.title} 
                            image={window.location.protocol+"//"+window.location.hostname + item.field_web_exhibit_image} 
                            description={item.field_web_exhibit_blurb}
                            nav={item.field_web_exhibit_link}
                            blank
                            />
                        )
                    })
                )}
            </div>
            {
                btnMore && (
                    <div className='d-flex justify-content-center'>
                        <SideBarBtn text={'More Web Exhibits'} func={handleOnclickMore} />
                    </div>
                )
            }
        </>
    )
}
