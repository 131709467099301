import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'styles/theme.scss';
import { Provider } from 'react-redux';
import { store } from 'services/Redux/store';
import AppRouter from 'routes/AppRouter';
import ReactGA from 'react-ga4';
const TRACKING_ID = 'G-HECN5V9MDM'; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	/* <React.StrictMode> */
	<Provider store={store}>
		<AppRouter />
	</Provider>
	/* </React.StrictMode> */
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
