import React, { useEffect } from 'react';
import ApiURLs from 'services/BackendApi/BackendApiURLs';
import { useWebExhibitsPage } from 'modules/website/hooks/useWebExhibitsPage';
import styles from './WebExhibitView.module.scss';
import { Col, Container, Row } from 'react-bootstrap';
import Alert from 'modules/website/components/Alert/Alert';
import ContentCard from 'modules/website/components/ContentCard/ContentCard';
import WebExhibistsLoading from './WebExhibistsLoading/WebExhibistsLoading';
import { useAliasNodePage } from 'modules/website/hooks/useAliasNodePage';
import ReactGA from 'react-ga4';

export default function WebExhibitView() {
	const { isLoading, isError, payload, error } = useWebExhibitsPage();
	const {
		isLoading: isLoadingInfo,
		isError: isErrorInfo,
		payload: payloadInfo,
		error: errorInfo,
	} = useAliasNodePage({
		alias: 'web-exhibits',
	});

	/* useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []); */
	return (
		<div>
			{isLoadingInfo && isLoading && <WebExhibistsLoading />}
			{isErrorInfo && isError && (
				<Alert variant='danger'>{errorInfo}</Alert>
			)}
			{!isLoadingInfo && !isErrorInfo && payloadInfo && payload && (
				<>
					<div className={`${styles.title} py-5`}>
						{payloadInfo.title[0].value}
					</div>
					<div className={`${styles.description}`}>
						<div
							dangerouslySetInnerHTML={{
								__html: payloadInfo.body[0].processed,
							}}
						/>
					</div>
					<div>
						<Container>
							<Row>
								{payload.data.map((item, index) => {
									return (
										<Col md={5} lg={6}>
											<ContentCard
												key={index}
												title={item.title}
												image={
													window.location.protocol +
													'//' +
													window.location.hostname +
													item.field_web_exhibit_image
												}
												description={
													item.field_web_exhibit_blurb
												}
												nav={
													item.field_web_exhibit_link
												}
												blank
											/>
										</Col>
									);
								})}
							</Row>
						</Container>
					</div>
				</>
			)}
		</div>
	);
}
