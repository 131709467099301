import React from "react";


export default function FullScreenButton(props){
    const {stylesheet,state} = props;
    if (state===true) 
    return <svg height="100%" version="1.1" viewBox="0 0 36 36" width="100%">
                <g>
                    <path className={stylesheet.iconfill} d="m 14,14 -4,0 0,2 6,0 0,-6 -2,0 0,4 0,0 z" id="gp-id-fs6"></path>
                </g>
                <g>
                    <path className={stylesheet.iconfill} d="m 22,14 0,-4 -2,0 0,6 6,0 0,-2 -4,0 0,0 z" id="gp-id-fs7"></path>
                </g>
                <g>
                    <path className={stylesheet.iconfill} d="m 20,26 2,0 0,-4 4,0 0,-2 -6,0 0,6 0,0 z" id="gp-id-fs8"></path>
                </g>
                <g>
                    <path className={stylesheet.iconfill} d="m 10,22 4,0 0,4 2,0 0,-6 -6,0 0,2 0,0 z" id="gp-id-fs9"></path>
                </g>
            </svg>;
    return <svg height="100%" version="1.1" viewBox="0 0 36 36" width="100%">
                <g>
                    <path className={stylesheet.iconfill} d="m 10,16 2,0 0,-4 4,0 0,-2 L 10,10 l 0,6 0,0 z" id="gp-id-fs2"></path>
                </g>
                <g> 
                    <path className={stylesheet.iconfill} d="m 20,10 0,2 4,0 0,4 2,0 L 26,10 l -6,0 0,0 z" id="gp-id-fs3"></path>
                </g>
                <g>  
                    <path className={stylesheet.iconfill} d="m 24,24 -4,0 0,2 L 26,26 l 0,-6 -2,0 0,4 0,0 z" id="gp-id-fs4"></path>
                </g>
                <g> 
                    <path className={stylesheet.iconfill} d="M 12,20 10,20 10,26 l 6,0 0,-2 -4,0 0,-4 0,0 z" id="gp-id-fs5"></path>
                </g>
            </svg>;
}