import React from 'react'
import { GeoAltFill, TagsFill } from 'react-bootstrap-icons'
import styles from './CatAndLoc.module.scss'
import { useNodePage } from 'modules/website/hooks/useNodePage';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function CatAndLoc(props) {
    const { taxonomies } = props;

    /* const categories = taxonomies.filter((element) => element.vid === 'category_video_tami');
    const locations = taxonomies.filter((element) => element.vid === 'location_tami'); */
    //console.log(payload);
    //console.log(taxonomies);
    return (
        <div className={`${styles.main}`}>
            {
                taxonomies?.categories.length > 0 && (
                    <div className={`${styles.categories}`}>
                        <div>
                            <TagsFill className={`${styles.icon}`} /> Categories:
                        </div>
                        {
                            taxonomies.categories.map((cat, index) => {
                                return (
                                    <div xs={4} sm={3} md={2} key={index} className={`${styles.option}`}>
                                        <Link key={index} className={styles.link} to={`/taxonomy/term/${cat.tid}`}>
                                            {cat.name}
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                )}
            {
                taxonomies?.locations.length > 0 && (
                    <div className={`${styles.categories}`}>
                        <div>
                            <GeoAltFill className={`${styles.icon}`} /> Texas Locations:
                        </div>
                        {
                            taxonomies.locations.map((loc, index) => {
                                return (
                                    <div xs={4} sm={3} md={2} key={index} className={`${styles.option}`}>
                                        <Link key={index} className={styles.link} to={`/taxonomy/term/${loc.tid}`}>
                                            {loc.name}
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        </div>
    )
}
