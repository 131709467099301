import React, { useEffect, useRef, useState } from 'react';
import { Navbar as NavbarBootstrap, Nav, Container, Button } from 'react-bootstrap';
import logo from 'assets/img/logo.png';
import { useNavigate } from 'react-router-dom';
import NavbarOffcanvas from 'react-bootstrap/esm/NavbarOffcanvas';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SearchBar from 'modules/website/components/SearchBar/SearchBar';
import SocialOptions from 'modules/website/components/SocialOptions/SocialOptions';
import styles from './Navbar.module.scss';
import { XLg } from 'react-bootstrap-icons';

/* const navbarOptions = [
	{
		name: 'Main',
		path: '/',
	},
	{
		name: 'Videos',
		path: '/videos',
	},
	{
		name: 'Web Exhibits',
		path: '/web-exhibits',
	},
	{
		name: 'News & Events',
		path: '/news-events',
	},
	{
		name: 'Education',
		path: '/education',
	},

	{
		name: 'Digitization',
		path: '/digitization',
	},
	{
		name: 'Film Round-Up',
		path: '/round-up',
	},
	{
		name: 'Licensing',
		path: '/licensing',
	},
	{
		name: 'Support Us',
		path: '/support-us',
	},
	{
		name: 'About',
		path: '/about',
	},
	{
		name: 'FAQ',
		path: '/faq',

	}
]; */

const navbarOptions = [
	{
		name: 'Videos',
		path: '/videos',
	},
	{
		name: 'Digitization',
		path: '/digitization',
	},
	{
		name: 'FAQ',
		path: '/faq',

	},
	{
		name: 'Education',
		path: '/education',
	},
	{
		name: 'News & Events',
		path: '/news-events',
	},
	{
		name: 'Web Exhibits',
		path: '/web-exhibits',
	},
	{
		name: 'Licensing',
		path: '/licensing',
	},
	{
		name: 'Film Round-Up',
		path: '/round-up',
	},
	{
		name: 'Support Us',
		path: '/support-us',
	},
	{
		name: 'About',
		path: '/about',
	},
];

export default function Navbar(props) {
	const [offcanvasOpen, setOffcanvasOpen] = useState(false);
	const navigate = useNavigate();
	const [isTouchDevice, setIsTouchDevice] = useState(
		'ontouchstart' in window
	);
	/* const handleOffcanvasClose = () => {
		setOffcanvasOpen(false);
	};

	const handleOutsideClick = (e) => {
		if (e.target !== document.querySelector('.offcanvas')) {
			handleOffcanvasClose();
		}
	};

	useEffect(() => {
		if (offcanvasOpen) {
			document.addEventListener('click', handleOutsideClick);
		} else {
			document.removeEventListener('click', handleOutsideClick);
		}
	}, [offcanvasOpen]); */

	return (
		<NavbarBootstrap bg='light' expand={false}>
			<Container fluid>
				<NavbarBootstrap.Brand onClick={() => navigate('/')} className={`${styles.brand}`}>
					<img
						src={logo}
						alt='React Bootstrap logo'
						className={`${styles.logo} d-inline-block align-center`}
					/>
				</NavbarBootstrap.Brand>
				<div className={`${styles.navOptions}`}>
					<div className={`${styles.otherOptions}`}>
						<div className={`${styles.searchbar}`}>
							<SearchBar />
						</div>
						<div className={`${styles.socialOptions}`}>
							<SocialOptions />
						</div>
					</div>
					<NavbarBootstrap.Toggle
						aria-controls='basic-navbar-nav'
						className='ms-auto'
						onClick={() => {
							//console.log('click');
							setOffcanvasOpen(true);
						}} />
				</div>
				<NavbarOffcanvas
					placement="end"
					id="offcanvasWithBothOptions"
					aria-labelledby="offcanvasWithBothOptionsLabel"
					className={`${styles.lateralBar} offcanvas`}
					show={offcanvasOpen}
				>
					<Offcanvas.Header className='justify-content-end' >
						<Button onClick={() => setOffcanvasOpen(false)} className={`${styles.closeBtn}`}>
							<XLg />
						</Button>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<div className={`${styles.otherOptionsOffcanva}`}>
							<div className={`${styles.searchbar}`}>
								<SearchBar offcanvas/>
							</div>
							<div className={`${styles.socialOptions}`}>
								<SocialOptions />
							</div>
							{/* <SearchBar offcanvas />
							<SocialOptions /> */}
						</div>
						<Nav className='ms-auto my-2 my-lg-0'>
							<Nav.Link href="/search">Search</Nav.Link>
						</Nav>
						{
							navbarOptions.map((option, index) => (
								<Nav className='ms-auto my-2 my-lg-0' key={index}>
									<Nav.Link onClick={() => {
										navigate(option.path);
										setOffcanvasOpen(false); // Cierra el Offcanvas al hacer clic en un enlace
									}}>
										{option.name}
									</Nav.Link>
								</Nav>
							))
						}
					</Offcanvas.Body>

				</NavbarOffcanvas>
				{/* <NavbarBootstrap.Collapse id='basic-navbar-nav navbar-expand-s'>
				</NavbarBootstrap.Collapse> */}
			</Container>
			<div className={`${styles.phoneSearch}`}>
				<SearchBar />
			</div>
		</NavbarBootstrap>
	);
}
