import React from 'react'
import { Placeholder } from 'react-bootstrap'
import styles from './NewsLoading.module.scss'
export default function NewsLoading() {
    return (
        <div className={`${styles.news} py-5`}>
            <div className={`${styles.image} d-flex justify-content-center`}>
                <Placeholder animation="wave" >
                    <Placeholder className={`${styles.placeimage}`} />
                </Placeholder>
            </div>
            <div className={`${styles.title}`}>
                <Placeholder as="p" animation="wave">
                <Placeholder xs={4} size="lg" />
                </Placeholder>

            </div>
            <div className={`${styles.content}`}>
            <Placeholder as="p" animation="wave">
                    <Placeholder xs={7} size="sm" /> <Placeholder xs={4} size="sm" />
                    <Placeholder xs={4} size="sm" /> <Placeholder xs={7} size="sm" />
                    <Placeholder xs={8} size="sm" /> <Placeholder xs={3} size="sm" />
                    <Placeholder xs={3} size="sm" /> <Placeholder xs={3} size="sm" /> <Placeholder xs={3} size="sm" /> <Placeholder xs={1} size="sm" />
                </Placeholder>
                <Placeholder as="p" animation="wave">
                    <Placeholder xs={6} size="sm" /> <Placeholder xs={5} size="sm" />
                    <Placeholder xs={5} size="sm" /> <Placeholder xs={6} size="sm" />
                    <Placeholder xs={7} size="sm" /> <Placeholder xs={4} size="sm" />
                    <Placeholder xs={8} size="sm" /> <Placeholder xs={3} size="sm" />
                    <Placeholder xs={4} size="sm" /> <Placeholder xs={7} size="sm" />
                    <Placeholder xs={3} size="sm" /> <Placeholder xs={8} size="sm" />
                </Placeholder>
                <Placeholder as="p" animation="wave">
                    <Placeholder xs={8} size="sm" /> <Placeholder xs={3} size="sm" />
                    <Placeholder xs={7} size="sm" /> <Placeholder xs={4} size="sm" />
                    <Placeholder xs={4} size="sm" /> <Placeholder xs={7} size="sm" />
                    <Placeholder xs={6} size="sm" /> <Placeholder xs={5} size="sm" />
                    <Placeholder xs={3} size="sm" /> <Placeholder xs={3} size="sm" /> <Placeholder xs={3} size="sm" />
                </Placeholder>
            </div>
        </div>
    )
}
