import React from 'react';
import styles from './GLifosMetadata.module.scss';
import AccordionItext from '../AccordionItext/AccordionItext';
import MetadataOptions from './MetadataOptions/MetadataOptions';
import CatAndLoc from './CatAndLoc/CatAndLoc';
import CreateSegment from '../CreateSegment/CreateSegment';
import Partnership from './Partnership/Partnership';
import GlifosMetadataLoading from './GlifosMetadataLoading/GlifosMetadataLoading';

export default function GLifosMetadata(props) {
	const { metadata, itext, extra, comments, nid } = props;
	//const typeOther = itext.filter((element) => element.type === 'other' || element.type === 'biography');
	let dateLinks = null;
	if (metadata?.dates !== undefined) {
		dateLinks = (
			<React.Fragment>
				{metadata.dates.map((item, index) => (
					<React.Fragment key={index}>
						<a href={`/search/?keywords=${item.date}`} key={index}>
							{item.date}
						</a>
						<span> | </span>
					</React.Fragment>
				))}
			</React.Fragment>
		);
	}

	return (
		<div className={`${styles.main}`}>
			{/* <div className={`${styles.segment}`}>
                <CreateSegment />
            </div> */}
			<div className={`${styles.title}`}>{metadata?.title}</div>
			<div className={`${styles.donor}`}>
				<a href={`/search/?donor=${metadata?.statistics?.donor?.id}`}>
					{metadata?.statistics?.donor?.donor}
				</a>
			</div>
			<div className={`${styles.statistics}`}>
				<a href={`/search/?audio=${metadata?.statistics?.audio?.id}`}>
					{metadata?.statistics?.audio?.audio}
				</a>
				{' | '}
				{dateLinks}
				<a href={`/search/?color=${metadata?.statistics?.color?.id}`}>
					{metadata?.statistics?.color?.color}
				</a>
				{' | '}
				<a href={`/search/?language=${metadata?.statistics?.language?.id}`}>
					{metadata?.statistics?.language?.language}
				</a>
			</div>
			<div>
				<AccordionItext itext={itext} extra={extra} />
			</div>
			<CatAndLoc taxonomies={metadata?.taxonomies} />
			<div>
				<MetadataOptions
					description={metadata?.description}
					keywords={metadata?.keywords}
					//others={extra?.biographies_id?(extra.biographies_id.result):([])}
					others={extra?.biographies_id || []}
					comments={comments}
					nid={nid}
				/>
			</div>
			{extra && (
				<Partnership sponsors={extra?.sponsors_id?.result || []} />
			)}
			{/* <GlifosMetadataLoading /> */}
		</div>
	);
}

/* 
arr.map((item, index) =>
    (
        <div className={`${styles.item}`} key={index}>
            <div className={`${styles.title}`}>
                {item[0]}:
            </div>
            <div className={`${styles.value}`}>
                {
                    Array.isArray(item[1]) ? (
                        item[1].map((item, index) => (
                            <div className={`${styles.value}`} key={index}>
                                {item}
                            </div>
                        ))
                    ) : (
                        typeof item[1] === 'object' ? (
                            Object.entries(item[1]).map((item, index) => {
                                return (
                                    <div className={`${styles.value}`}>
                                        {item[0]}: {item[1]}
                                    </div>
                                )
                            })
                        ) : (
                            item[1]
                        )
                    )
                }
            </div>
        </div>
        /* console.log(typeof item[1], item[1])
    )
)
*/
