import { useEffect, useState } from 'react';
import apiService from 'services/BackendApi/BackendApi';

export function useEducationOption(props) {
	const { type } = props;
	const [pageContent, setPageContent] = useState({
		isLoading: false,
		isError: false,
		payload: null,
		error: null,
	});

	useEffect(() => {
		setPageContent((prevPageContent) => ({
			...prevPageContent,
			isLoading: true,
		}));
		apiService
			.GetEducationOptions(
				{
					urlParams: {
						type: type,
					}
				}
			)
			.then((response) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: false,
					payload: {
						...response.data,
					},
				}));
			})
			.catch((error) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: true,
					error: error?.message,
				}));
			})
			.finally(() => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isLoading: false,
				}));
			});
	}, [type]);

	return pageContent;
}

export function useEducationPagination(props) {
	const { page, respp,sortby} = props;
	const [pageContent, setPageContent] = useState({
		isLoading: false,
		isError: false,
		payload: null,
		error: null,
	});
	useEffect(() => {
		setPageContent((prevPageContent) => ({
			...prevPageContent,
			isLoading: true,
		}));
		apiService
			.GetPagination(
				{
					params: {
						page: page,
						respp: respp,
						contenttype: 'content_lesson_plan',
						sortby: sortby,
					},
				}
			)
			.then((response) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: false,
					payload: {
						...response.data,
					},
				}));
			})
			.catch((error) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: true,
					error: error?.message,
				}));
			})
			.finally(() => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isLoading: false,
				}));
			});
	}, [page]);

	return pageContent;

}