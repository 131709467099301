import React from 'react'
import { Placeholder } from 'react-bootstrap'

export default function BrowseByLoading() {
    return (
        <ul style={{
            listStyle: 'none',
            padding: '0',
        }}>
            <Placeholder as="li" animation="wave">
                <Placeholder xs={4} size="sm" />
            </Placeholder>
            <Placeholder as="li" animation="wave">
                <Placeholder xs={5} size="sm" />
            </Placeholder>
            <Placeholder as="li" animation="wave">
                <Placeholder xs={6} size="sm" />
            </Placeholder>
            <Placeholder as="li" animation="wave">
                <Placeholder xs={2} size="sm" />
            </Placeholder>
            <Placeholder as="li" animation="wave">
                <Placeholder xs={6} size="sm" />
            </Placeholder>
            <Placeholder as="li" animation="wave">
                <Placeholder xs={3} size="sm" />
            </Placeholder>
            <Placeholder as="li" animation="wave">
                <Placeholder xs={4} size="sm" />
            </Placeholder>
            <Placeholder as="li" animation="wave">
                <Placeholder xs={2} size="sm" />
            </Placeholder>
            <Placeholder as="li" animation="wave">
                <Placeholder xs={4} size="sm" />
            </Placeholder>
            
        </ul >
    )
}
